@import '../variables.scss';

#login {

   min-height: 100vh;
   padding-bottom: 50px;
   box-sizing: border-box;
   background: linear-gradient(0deg,
         transparentize(adjust-color($secondary-color, $red: -20, $blue: -20, $green: -20), 0.98) 20%, 
         transparentize(adjust-color($secondary-color, $red: -20, $blue: -20, $green: -20), 0.92) 100%); 


   #login-form-container {

      position: relative;
      display: block;
      margin: 0 auto;
      max-width: 450px;
      z-index: 2;

      #auto-logout {

         position: relative;
         margin: -3px 0 15px;
         font-size: .75rem;
         padding: 14px 13px 14px 35px;
         background: $secondary-color-90-transparant;
         color: $secondary-color;
         border-radius: 7px;

         svg {
            position: absolute;
            height: .8rem;
            width: .8rem;
            top: 50%;
            left: 13px;
            fill: $secondary-color;
            @include transform(translateY(-50%));
         }

      }

      .form-container {

         & > p {
            
            font-size: .9rem;
            
            &.requested {
               margin-bottom: 0;
            }

            &.referral {
               padding: 15px;
               margin-top: 10px;
               border-radius: 10px;
               font-size: .8rem;
               background: $secondary-color-80-transparant;
            }

         }

         form {

            .btn-container {
               max-width: none;
            }

            #forgot-password {
               font-size: .65rem;
               margin: -2px 0 18px;
               text-align: center;
            }

            #login-form-types {

               display: flex;
               justify-content: space-between;
               flex-wrap: wrap;
               overflow: hidden;
               max-height: 0;
               @include transition(max-height 0.4s);

               & > h4 {
                  flex: 0 0 100%;
                  margin-top: 10px;
                  font-size: 1rem;
               }

               input[type=radio] {
                  
                  position: absolute;
                  opacity: 0;
                  width: .1px;
                  height: .1px;
                  visibility: hidden;
                  z-index: -1;

                  &#login-form-user-type-user:checked ~ #login-form-user-type-user-label {
                     opacity: 1;
                  }

                  &#login-form-user-type-editor:checked ~ #login-form-user-type-editor-label {
                     opacity: 1;
                  }

                  &#login-form-user-type-user:checked ~ #login-form-user-type-user-p {
                     display: block;
                  }

                  &#login-form-user-type-editor:checked ~ #login-form-user-type-editor-p {
                     display: block;
                  }

               }

               label {

                  display: flex;
                  position: relative;
                  justify-content: center;
                  align-items: center;
                  flex-wrap: wrap;
                  flex: 0 0 calc(50% - 3px);
                  padding: 20px 0;
                  border-radius: 10px;
                  box-sizing: border-box;
                  border:$light-border;
                  cursor: pointer;
                  opacity: .3;
                  @include transition(opacity .2s);

                  svg {
                     height: 72px;
                     width: 72px;
                     margin-bottom: 10px;
                  }

                  h4 {
                     flex-basis: 100%;
                     font-size: 1.05rem;
                     margin: 0;
                     text-align: center;
                  }

               }

               & > p {
                  
                  display: none;
                  position: relative;
                  flex-basis: 100%;
                  margin-bottom: 8px;
                  font-size: .8rem;
                  box-sizing: border-box;
                  padding: 12px 45px 12px 15px;
                  margin-top: 6px;
                  border-radius: 8px;
                  color: $secondary-color;
                  background: $secondary-color-90-transparant;

                  & > svg {
                     position: absolute;
                     right: 12px;
                     top: 50%;
                     height: 20px;
                     fill: transparentize($color: $secondary-color, $amount: 0.8);
                     @include transform(translateY(-50%));
                  }

               }

            }

            .valid + #login-form-types {
               max-height: 350px;
            }

         }

      }

      #login-form-privacy {
         font-size: .65rem;
         padding-top: 8px;
         text-align: center;
      }

   }

   & > svg {

      position: absolute;
      bottom: 0;
      left: -5vw;
      width: 105vw;
      z-index: 1;
      pointer-events: none;

      &:last-child {
         width: 120vw;
         left: -10vw;
         @include transform(scaleX(-1));
      }

      path {
         fill: $secondary-color;
         opacity: .04;
      }

   }

}