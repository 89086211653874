@import '../variables.scss';

#ad {

   background: $secondary-color-95-transparant;
   padding-bottom: 100px;
   margin-bottom: 0px;

   h3 {
      font-size: 1.2rem;
   }

   p {
      font-size: .9rem;
   }

   .box {
      padding: 25px 25px;
      box-sizing: border-box;
      background: rgb(255, 255, 255);
      border-radius: 20px;
      border:$light-border;
   }

   &.loading {

      .box {

         h3, p, svg, span, div {
            display: none !important;
         }

         .placeholder {
            
            position: relative;
            display: block !important;
            border-radius: 7px 7px 0 0;
            background: $secondary-color-90-transparant;
            overflow: hidden;

            &:after {
               content: '';
               position: absolute;
               top: -20%;
               left: 40%;
               width: 280%;
               height: 140%;
               background: linear-gradient(105deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0) 60%);
               animation: shine 1.3s infinite;
            }

         }

         @keyframes shine {
            0% {
               left: 40%;
            }
            100% {
               left: -170%;
            }
         }

         .img-placeholder {
            height: 350px;
            width: 100%;
         }

         .h3-placeholder {
            
            height: 1.3rem;
            width: 150px;
            margin-bottom: 10px;
            border-radius: 10px;
            background: $secondary-color-80-transparant;;

            &:after {
               animation-delay: 0.4s;
            }

         }

         .p-placeholder {

            height: 150px;
            width: 100%;
            border-radius: 10px;
            background: transparentize($color: $p-color, $amount: 0.95);

            &:after {
               animation-delay: 0.2s;
            }

            &.large {
               height: 350px;
            }

         }

      }

   }

   #hero {

      h1, h1 .stress {
         font-size: 1.4rem;
         line-height: 2.2rem;
         color: $secondary-color;
      }

      h1 {
         
         display: flex;
         justify-content: space-between;
         align-items: center;
         padding: 0 0 12px;
         margin: -3px 0 15px;
         box-sizing: border-box;
         border-bottom: $light-border-thin;

         &, span {
            font-family: $p-font;
         }

      }



      #hero-bg {

         .light-blue {
            box-shadow: none;
         }
      }

   }

   #breadcrumb {

      padding: 0 0 15px;
      font-size: .85rem;
      

      a, span {
         font-family: $p-font;
         line-height: 2em;
      }

      a {
         
         padding: 6px 8px;
         color: $secondary-color;
         background: rgb(255, 255, 255);
         border-radius: 12px;
         border:$light-border;;

         &:first-child {
            margin-left: -2px;
         }

         & + svg {
          
            height: 1em;
            width: 1em;
            margin: 0 1px -2px 3px;
            fill: $secondary-color;

            & + span {
               color: $secondary-color;
            }

         }

      }

      .placeholder {

         position: relative;
         display: inline-block;
         height: calc(1.05rem + 12px);
         width: 100px;
         background: rgb(255, 255, 255, 0.8);
         border-radius: 20px;
         border: $light-border;
         overflow: hidden;

         &:after {
            content: '';
            position: absolute;
            top: -20%;
            left: 40%;
            width: 280%;
            height: 140%;
            background: linear-gradient(105deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.025), rgba(0, 0, 0, 0) 60%);
            animation: shine 1.3s infinite 0.15s;
         }

         @keyframes shine {
            0% {
               left: 40%;
            }
            100% {
               left: -170%;
            }
         }

      }

   }
   
   #info {

      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 30px;
      margin: 0px auto 50px;
      border-bottom: 1px solid rgb(211, 211, 211);

      #read-info {

         position: relative;
         flex: 0 0 66%;

         & > * {
            margin-bottom: 10px;
         }

         @media screen and (max-width: 972px) {
            flex-basis: 100%;
         }

         & > h3 {
            margin-bottom: 7px;
            border-top: none;
            padding-top: 0;
         }

         & > p {
            margin-bottom: 10px;
            font-size: .95rem;
         }

         .favorite-form {
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 99;
         }

         #info-images {

            position: relative;
            box-sizing: border-box;
            padding: 0;
            width: 100%;
            margin-bottom: 10px;
            border-radius: 20px 20px 0 0;
            border: none;
            box-shadow: none;

            #close-images {

               position: fixed;
               top: 0;
               left: 0;
               right: 0;
               bottom: 0;
               background: transparentize(black, 0.3);
               z-index: 99999;
               animation: fade-in-close-images 0.3s;

               @keyframes fade-in-close-images {
                  0% {
                     opacity: 0;
                  }

                  100% {
                     opacity: 1;
                  }
               }

            }

            .others {

               position: relative;
               height: 350px;
               width: 100%;
               flex-wrap: wrap;
               justify-content: space-between;
               background: white;
               border-radius: 20px;
               box-sizing: border-box;
               filter: saturate(.95);

               @media screen and (max-width: 1200px) {
                  height: 320px;
               }

               .img-container, .camera-placeholder {

                  position: absolute;
                  background: rgb(230, 230, 230);

                  &:first-child {
                     top: 0;
                     left: 0;
                     height: 100%;
                     width: 50%;
                     border-radius: 20px 0 0 0;

                     &:after, img {
                        border-radius: 20px 0 0 0;
                     }

                  }

                  &:nth-child(2) {

                     top: 0;
                     right: 0;
                     height: 50%;
                     width: calc(50% - 2px);
                     border-top-right-radius: 20px;

                     &:after, img {
                        border-top-right-radius: 20px;
                     }

                  }

                  &:nth-child(3) {
                     left: calc(50% + 2px);
                     width: calc(25% - 2px);
                     bottom: 0;
                     height: calc(50% - 2px);
                  }

                  &:nth-child(4) {

                     right: 0;
                     bottom: 0;
                     height: calc(50% - 2px);
                     width: calc(25% - 2px);

                  }

                  img {

                     width: 100%;
                     height: 100%;
                     object-fit: cover;
                     cursor: pointer;
   
                  }

                  svg {
                     position: absolute;
                     top: 50%;
                     left: 50%;
                     transform: translate(-50%, -50%);
                     fill: rgb(210, 210, 210);
                  }


                  
               }

               &.active {
                  
                  position: fixed;
                  top: 50%;
                  left: 50%;
                  width: 90vw;
                  height: min(700px, calc(100vh - 200px), 70vw);
                  max-width: 1000px;
                  @include transform(translate(-50%, -50%));
                  animation: fade-in-images 0.3s;
                  z-index: 999999;
                  user-select: none;
                  border-radius: 0;
                  -moz-user-select: none;
                  -khtml-user-select: none;
                  -webkit-user-select: none;
                  -o-user-select: none;
                  
                  @keyframes fade-in-images {
                     0% {
                        opacity: 0;
                        @include transform(translate(-50%, calc(-50% - 13px)));
                     }

                     100% {
                        opacity: 1;
                        @include transform(translate(-50%, -50%));
                     }
                  }

                  .camera-placeholder {
                     display: none;
                  }


                  .img-container {

                     display: none;
                     top: 0;
                     left: 0;
                     right: 0;
                     bottom: 0;
                     width: 100%;
                     height: 100%;
                     cursor: default;
                     background: rgb(15, 15, 15);
                     border-radius: 0;

                     &:after, &:hover:after {
                        opacity: 0;
                     }

                     &.show {
                        display: block;
                     }

                     img {
                        display: block;
                        margin: 0 auto;
                        cursor: default;
                        height: 100%;
                        width: auto;
                        border-radius: 0;
                     }

                     &:first-child {

                        .scroll-image.left {
                           display: none;
                           pointer-events: none;
                        }

                     }

                     &:last-child {

                        .scroll-image.right {
                           display: none;
                           pointer-events: none;
                        }

                     }

                     .scroll-image {
                        
                        position: absolute;
                        height: 100%;
                        width: 28px;
                        top: 0;
                        z-index: 2;
                        cursor: pointer;
                        opacity: 1;
                        @include transition(opacity 0.2s);
                        
                        &:before {

                           content: '';
                           position: absolute;
                           top: 0;
                           bottom: 0;
                           left: 0;
                           right: 0;
                           background: rgb(255, 255, 255);
                           @include transition(opacity 0.2s);

                        }

                        &.right {
                           right: -2px;
                           overflow: hidden;
                           svg {
                              left: 8px;
                           }
                        }

                        &.left {
                           left: 0;
                           overflow: hidden;
                           svg {
                              left: 7px;
                              @include transform(translateY(-50%) rotate(180deg));
                           }

                        }
                        
                        svg {
                           position: absolute;
                           top: 50%;
                           width: 14px;
                           height: 14px;
                           fill: $secondary-color;
                           @include transform(translateY(-50%));
                        }

                     }

                     .shrink-image {
                        
                        display: none;
                        position: absolute;
                        padding: 6px;
                        top: 10px;
                        right: 10px;
                        opacity: 1;
                        fill: rgb(255, 255, 255);
                        cursor: pointer;
                        z-index: 3;

                        svg {
                           width: 20px;
                           height: 20px;
                        }

                     }

                  }
   
               }

               &:first-of-type {

                  .scroll-image, .shrink-image {
                     display: none;
                  }

               }

            }
         }

         #info-activities {

            position: relative;
            padding: 10px 5px 5px;
            margin: -64px 3px 20px;
            z-index: 10;

            .tag {
               padding: 5px 10px;
               font-size: 12px;
               font-family: $p-font;
               border-radius: 4px;
               border: 1px solid rgb(210, 210, 210);
               background: rgb(255, 255, 255);
            }

         }

         #info-people-price {

            position: relative;
            margin: -11px 0 5px;
            box-sizing: border-box;
            font-family: $p-font;
            font-size: .9rem;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            box-shadow: none;
            border-top: none;
            overflow: hidden;

            .rating {
               margin: 12px 0 20px;
               padding-bottom: 20px;
               border-bottom: $light-border-thin;
            }

            #description-hours {

               display: flex;
               align-items: flex-start;
               flex-wrap: wrap;

               #description-hours-left {
                  flex: 1 1 calc(100% - 220px);

                  @media screen and (max-width: 572px) {
                     flex-basis: 100%;
                  }

               }

               #description-hours-right {
                  
                  flex: 0 0 200px;
                  padding-left: 20px;
                  margin-left: 20px;
                  box-sizing: border-box;
                  border-left: $light-border-thin;

                  @media screen and (max-width: 572px) {
                     flex-basis: 100%;
                     margin-left: 0;
                     margin-top: 15px;
                     padding-left: 0;
                     padding-top: 25px;
                     border-left: none;
                     border-top: $light-border-thin;
                  }

               }
               
               #short-description {
                  margin-bottom: 10px;
                  font-weight: 600;
                  word-break: break-word;
               }

               .types {

                  align-items: center;
                  margin: 5px 0 10px;

                  p {
                     display: block;
                     width: 100%;
                     margin-bottom: 4px;
                  }
   
                  & > span {
                     
                     display: inline-block;
                     padding: 10px 12px;
                     margin: 0 3px 3px 0;
                     font-size: .8rem;
                     line-height: .8rem;
                     border-radius: 10px;
                     background: rgb(241, 241, 241);
   
                     &.option {
                        background: $secondary-color-90-transparant;
                        color: $secondary-color;
                     }
   
                  }
   
               }  

               #opening-hours {
                  
                  padding: 16px 25px;
                  box-sizing: border-box;
                  border-radius: 20px;
                  background: $secondary-color-90-transparant;

                  li {

                     display: flex;
                     justify-content: space-between;
                     align-items: center;
                     padding: 6px 0;
                     border-bottom: 1px solid rgb(220, 220, 220);

                     &:first-child {
                        padding-top: 0;
                     }

                     &:last-child {
                        padding-bottom: 0;
                        border-bottom: none;
                     }

                     span {
                        
                        font-size: .8rem;
                        color: $secondary-color;

                        &:only-child {
                           width: 100%;
                           text-align: center;
                        }

                     }

                     &.closed {

                        span:not(:first-child) {
                           color: $secondary-color-50-transparant;
                        }

                     }

                  }

               }

            }            

            & > span {

               display: flex;
               align-items: center;
               margin: 6px 0;
               border-radius: 5px;

               &:first-of-type {
                  margin-top: 14px;
               }
               &:last-of-type {
                  margin-bottom: 20px;
               }

               @media screen and (max-width: 972px) {
                  margin: 10px 0;
               }

               .icon {

                  display: inline-block;
                  flex: 0 0 18px;
                  height: 18px;
                  width: 18px;
                  margin: 0 10px 0 0;
                  border-radius: 4px;
                  background: $secondary-color;
                  border: 1px solid $secondary-color;
                  overflow: hidden;

                  svg {
                     height: 18px;
                     width: 18px;
                     padding: 6px 0;
                     fill: rgb(255, 255, 255)
                  }

               }

            }

         }

         #info-features {

            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            box-sizing: border-box;
            font-family: $p-font;
            margin-top: 10px;

            @media screen and (max-width: 972px) {
               flex-basis: 100%;
               margin-bottom: 10px;
            }

            h3 {
               flex-basis: 100%;
               margin-bottom: 5px;
            }

            p {
               
               flex-basis: 100%;
               margin-bottom: 10px;

               &:last-child {
                  margin-bottom: 0;
               }

            }

            .feature {

               @media screen and (max-width: 700px) {
                  flex-basis: 100%;
               }

               position: relative;
               flex: 0 0 calc(50% - 2px);
               display: flex;
               align-items: center;
               font-family: $header-font;
               box-sizing: border-box;
               border-radius: 15px;
               padding: 10px 15px;
               margin-bottom: 4px;
               background: $secondary-color-90-transparant;
               color: $secondary-color;
               overflow: hidden;

               .icon {

                  position: absolute;
                  right: 16px;
                  height: 18px;
                  width: 18px;
                  margin: 1px 10px 0 3px;
                  border-radius: 4px;

                  svg {
                     position: absolute;
                     top: calc(50% - 24px);
                     height: 48px;
                     width: 48px;
                     fill: transparentize($color: $secondary-color, $amount: 0.92);
                  }

               }

               span {
                  display: inline-block;
                  padding: 8px 0;
                  font-family: $p-font;
                  font-size: .8rem;
                  line-height: .8rem;
               }

            }

         }
   

         #info-map {
            
            display: flex;
            flex-wrap: wrap;
            position: relative;
            box-sizing: border-box;
            align-items: flex-start;
            justify-content: space-between;
            background: rgb(255, 255, 255);
            overflow: hidden;

            @media screen and (max-width: 972px) {
               flex-basis: 100%;
               margin-bottom: 10px;
            }

            @media screen and (max-width: 572px) {
               height: auto;
            }

            h3 {
               flex: 0 0 100%;
               margin-bottom: 10px;
            }

            #transport-features {

               flex: 0 0 calc(50% - 2px);

               @media screen and (max-width: 572px) {
                  flex: 0 0 100%;  
               }

               .transport-feature {

                  position: relative;
                  display: flex;
                  align-items: center;
                  box-sizing: border-box;
                  border-radius: 15px;
                  padding: 10px 15px;
                  margin-bottom: 4px;
                  background: $secondary-color-90-transparant;
                  color: $secondary-color;
                  overflow: hidden;

                  @media screen and (max-width: 572px) {
                     max-width: 100%;
                  }

                  .icon {

                     position: absolute;
                     right: 16px;
                     height: 18px;
                     width: 18px;
                     margin: 1px 10px 0 3px;
                     border-radius: 4px;
   
                     svg {
                        position: absolute;
                        top: calc(50% - 24px);
                        height: 48px;
                        width: 48px;
                        fill: transparentize($color: $secondary-color, $amount: 0.92);
                     }
   
                  }

                  span {
                     display: inline-block;
                     padding: 8px 0;
                     font-family: $p-font;
                     font-size: .8rem;
                     line-height: .8rem;
                     color: $secondary-color;
                  }

               }

            }

            #maptile-wrapper {

               flex: 0 0 calc(50% - 4px);

               @media screen and (max-width: 572px) {
                  flex: 0 0 100%;  
               }

               #map-street-city {
                  
                  position: relative;
                  display: flex;
                  align-items: center;
                  font-size: .8rem;
                  margin-top: 4px;
                  padding: 12px 15px;
                  box-sizing: border-box;
                  width: 100%;
                  background: $secondary-color-90-transparant;
                  color: $secondary-color;
                  border-radius: 10px;

                  .icon {
                     margin-right: 10px;
                     height: 1.2rem;
                     width: 1.2rem;
                     fill: $secondary-color-50-transparant;
                  }

                  .link {
                     position: absolute;
                     right: 6px;
                     top: 6px;
                     height: 12px;
                     width: 12px;
                     fill: $secondary-color-50-transparant;
                  }

               }
   
            }

         }

         #info-description {

            position: relative;
            display: block;
            max-height: 200px;
            overflow: hidden;
            width: 100%;

            &.expand {
               
               max-height: none;

               &:after {
                  display: none;
               }

            }

            &:after {

               content: 'Meer lezen...';
               position: absolute;
               bottom: 0;
               left: 0;
               right: 0;
               padding: 120px 0 20px;
               text-align: center;
               font-family: $p-font;
               background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 95%);
               border-top: none;
               border-radius: 0 0 10px 10px;
               cursor: pointer;

            }

            h3 {
               margin-bottom: 5px;
            }

            p {
               
               margin-bottom: 15px;
               font-size: .9rem;
               line-height: 1.2rem;
               word-break: break-word;

               &:last-of-type {
                  margin-bottom: 0;
               }

            }

         }

         #info-ratings {

            position: relative;

            #activity-ratings {
               position: absolute;
               margin-top: -90px;
            }

            h3 {
               margin-bottom: 10px;
            }

            .rating-box {

               margin-top: 15px;
               padding: 15px 0;
               border-top: $light-border-thin;

               h4 {
                  
                  font-size: .9rem;
                  margin: 10px 0 2px;

                  span {
                     color: $secondary-color-50-transparant;
                     font-size: .9rem;
                  }

               }

               p {
                  font-size: .9rem;
               }

            }

         }

      }

      #request-info {

         position: sticky;
         top: 50px;
         flex: 0 0 31%;

         @media screen and (max-width: 972px) {
               
            & {

               position: fixed;
               display: block;
               top: 0;
               width: calc(100vw - 50px);
               height: 100vh;
               max-width: 500px;
               right: -100%;
               z-index: 9999;
               @include transition(right 0.3s);

               &.show {
                  
                  right: -0;

                  #close-request {
                     pointer-events: all;
                  }

               }

               .form-container {

                  height: 100%;
                  padding: 90px 25px 20px;
                  border-radius: 0;
                  background: rgb(255, 255, 255);
                  border: none;
                  box-sizing: border-box;
                  border-left: 1px solid #d8d8d8;

                  .form-buttons {

                     .btn-container {
                        width: 100%;
                        max-width: 100%;
                     }

                  }                  

               }

               #close-request {

                  display: block;
                  position: absolute;
                  top: 0;
                  right: calc(100vw - 100px);
                  width: max(50px, calc(100vw - 50px));
                  height: 100vh;
                  z-index: 999999;
                  pointer-events: none;

               }

            }

         }

         .form-container {

            & > p {
               margin-top: -5px;
               font-size: .8rem;
            }

            .btn-container {
               width: 100%;
            }

            .date-input {
               
               position: relative;
               z-index: 9999;

               * {
                  font-family: $p-font;
               }

            }

         }

         h4 {
          
            font-size: .7rem;
            text-transform: uppercase;
            margin-top: 10px;
            margin-bottom: 7px !important;

            & + p {
               display: block;
               margin-bottom: 10px;
            }

         }

         p:last-of-type {
            font-size: .7rem;
            margin: 10px 0;
         }

         #request-info-insufficient {
            
            margin-top: 10px;
            padding: 12px 15px;
            background: rgba(200, 0, 0, 0.1);
            border-radius: 7px;   

            &.blue {
               
               background: transparentize($secondary-color, 0.87);

               p {
                  
                  color: $secondary-color;
               }

            }

            p {
               color: $error-color;
               font-size: .7rem;
               line-height: 1.3em;
               margin: 0;
            }

         }

         #direct-link {
            
            width: 100%;
            margin-top: 15px;
            padding-top: 14px;
            border-top: 1px solid rgb(211, 211, 211);

            .btn-container {
               
               max-width: 100%;
               width: 100%;

               .btn {
                  background-color: $secondary-color;
                  color: rgb(255, 255, 255);
               }

            }
            
         }

         #direct-call {
            
            width: 100%;
            margin-top: 15px;
            padding-top: 14px;
            border-top: 1px solid rgb(211, 211, 211);


            @media screen and (max-width: 972px) {
               display: none;
            }

            .btn-container {
               
               max-width: 100%;
               width: 100%;

               a {
                  
                  font-size: .7rem;
                  background: $primary-color;
                  border-color: $primary-color;
                  box-shadow: 0 2px 20px rgba(0, 26, 66, .1);

                  &:hover:not(.transitioned) {
                     background: adjust-color($color: $primary-color, $red: -15, $green: -15, $blue: -15);
                     border-color: adjust-color($color: $primary-color, $red: -15, $green: -15, $blue: -15);
                  }

                  &.transitioned {
                     cursor: default;
                  }

                  & + svg {
                     height: .8rem;
                     width: .8rem;
                  }

                  &.transition {
                     color: transparent;
                  }

                  &.disabled, &.disabled:hover {
                     background: rgb(150, 150, 150);
                     border-color: rgb(150, 150, 150);
                  }

               }

            }

         }

         #direct-call-mobile {

            display: none;
            position: fixed;
            bottom: 10px;
            right: 8px;
            height: calc(.85rem + 40px);
            width: calc(.85rem + 40px);
            background: $primary-color;
            border-radius: 10px;
            box-shadow: 0 2px 20px rgba(0, 26, 66, .1);
            z-index: -1;
            @include transition(background-color 0.2s);

            @media screen and (max-width: 972px) {
               display: block;
            }

            &:hover {
               background: adjust-color($primary-color, $red: -15, $blue: -15, $green: -15);
            }

            svg {
               position: absolute;
               top: 50%;
               left: 50%;
               height: 20px;
               width: 20px;
               fill: rgb(255, 255, 255);
               @include transform(translate(-50%, -50%));
            }

         }

      }

   }

   #recommendations {

      position: relative;

      h3 {
         font-size: 1.5rem;
         margin-bottom: 7px;
      }

      h3 + .subtitle {
         margin-bottom: 40px;
         font-size: 1rem;
      }

      .ads {
         position: relative;
         display: flex;
         justify-content: space-between;
         flex-wrap: wrap;
         align-items: flex-start;
         margin-bottom: 15px;
         
         .ad {
            
            flex: 0 0 calc(100% / 3 - 5px);
            margin-bottom: 10px;

            p.subtitle {
               font-size: .8rem;
            }

            @media screen and (max-width: 972px) {

               & {
                  display: block;
                  margin-left: auto;
                  margin-right: auto;
                  flex: 0 0 100%;      
                  max-width: 500px;
               }
   
            }


         }

      }

      .btn-container {
         display: block;
         max-width: 300px;
         width: 90%;
         margin: 0 auto;
      }

   }

   #show-request {
      display: none;

      @media screen and (max-width: 972px) {
                
         display: block;
         position: fixed;
         right: 10px;
         bottom: 10px;
         width: calc(100% - 20px);
         max-width: 400px;
         z-index: 2;
         box-shadow: 0 2px 20px rgba(0, 26, 66, .1);

         @media screen and (max-width: 500px) {
            max-width: 500px;
         }

      }

   }


   .edit-activity-btn {

      position: absolute;
      top: 10px;
      right: 10px;
      width: 18px;
      height: 18px;
      padding: 5px;
      background: rgb(255, 255, 255);
      border-radius: 50%;
      border: $light-border;
      z-index: 99;
      cursor: pointer;
      @include transition(background-color 0.2s);

      &:hover {
         background: $secondary-color-95-transparant;
      }

      &.required {
         border-radius: 10px;
      }

      svg {
         width: 18px;
         height: 18px;
         padding: 2px;
         box-sizing: border-box;
         margin-bottom: 3px;
         fill: $secondary-color;
      }

      span {
         display: inline-block;
         height: 18px;
         padding: 0 3px;
         margin-left: 4px;
         font-size: 13px;
         line-height: 18px;
         color: $error-color;
         @include transform(translateY(-7px));
      }

      &.required {
         
         width: auto;

         svg {
            margin-left: 7px;
         }

      }


   }

   #close-edit-form {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: calc(100% - max(10%, calc(100% - 800px)));
      bottom: 0;
      z-index: 99;
   }

   #edit-form-wrapper {

      position: fixed;
      top: 120px;
      right: 12px;
      bottom: 12px;
      width: calc(100vw - 24px);
      max-width: 800px;
      z-index: 99999;
      background: rgb(255, 255, 255);
      border: $light-border;
      border-radius: 30px;
      box-shadow: $bottom-shadow-strong;
      opacity: 1;
      animation: fade-in 0.3s;
      overflow-y: auto;
      @include transition(transform 0.3s);

      @keyframes fade-in {
         0% {
            transform: translateX(100%);
         }
         100% {
            transform: translateX(0);
         }
      }

      &.fading-out {
         transform: translateX(100%);
         pointer-events: none;
      }

      #edit-form {
   
         .form-container {
            
            padding: 30px;
            box-sizing: border-box;
            border: none;
   
            @media screen and (max-width: 768px) {
               width: 100%;
            }
   
            .form-buttons.multiple {
               
               margin-top: 15px;
               padding-top: 6px;
               border-top: 1px solid $secondary-color-90-transparant;
   
            }
   
         }
   
         #edit-activity-images-container {
   
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
   
            #edit-activity-images-warnings {
               
               flex: 0 0 100%;
   
               ul {
                  margin-top: 8px;
               }
   
               li {
                  color: $error-color;
                  padding: 3px 0;
                  font-family: $p-font;
                  font-size: .8rem;
               }
   
            }
   
            .edit-activity-image {
   
               position: relative;
               flex: 0 0 calc(25% - 4px);
               height: 200px;
               border-radius: 7px;
   
               img {
                  width: 100%;
                  height: 200px;
                  margin-bottom: 0;
                  border-radius: 7px;
                  box-sizing: border-box;
                  border: 1px solid rgb(210, 210, 210);
                  object-fit: cover;
               }
   
               &.upload {
                  
                  box-sizing: border-box;
                  background: rgb(255, 255, 255);
                  
                  img {
                     margin: -2px;
                     padding: 1px;
                     border: 2px dotted $secondary-color;
                     opacity: .7;
                  }
   
               }
   
               @media screen and (max-width: 572px) {
                  
                  flex: 0 0 calc(50% - 5px);
                  margin-bottom: 10px;
                  height: 120px;
                  max-height: 30vh;
   
                  img {
                     height: 120px;
                     max-height: 30vh;
                  }
   
               }
   
               .remove-image, .move-image {
                  position: absolute;
                  right: -3px;
                  top: -3px;
                  height: 12px;
                  width: 12px;
                  margin: 0;
                  padding: 8px;
                  background: rgb(255, 255, 255);
                  border: $light-border;
                  border-radius: 18px;
                  fill: $secondary-color;
                  cursor: pointer;
               }

               .remove-image {
                  border-color: $error-color;
                  fill: $error-color;
               }

               .move-image {
                  
                  top: auto;
                  bottom: -3px;

                  &.left {
                     right: auto;
                     left: -3px;
                     @include transform(rotate(180deg));
                  }

               }
   
               .toggle-order {
   
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  height: 12px;
                  width: 12px;
                  margin: 0;
                  padding: 8px;
                  background: rgb(255, 255, 255);
                  fill: rgb(50, 0, 0);
                  cursor: pointer;
                  @include transform(rotate(180deg));
                  border-radius: 0 0 0 4px;
   
                  &.right {
                     left: auto;
                     right: 0;
                     @include transform(none);
                     border-radius: 4px 0 0 0;
                  }
   
               }

               &.inactive {
                  
                  box-sizing: border-box;
                  border: 2px solid $secondary-color-80-transparant;
                  background: $secondary-color-90-transparant;

                  .add-image {
                     position: absolute;
                     top: 50%;
                     left: 50%;
                     height: 20px;
                     width: 20px;
                     fill: $secondary-color-50-transparant;
                     @include transform(translate(-50%, -50%));
                  }

               }
   
            }
   
            #edit-activity-images-upload {
   
               position: relative;
               display: inline-block;
               width: 100%;
               text-align: center;
               font-family: $p-font;
               font-size: .85rem;
               color: $secondary-color;
               padding: 15px 0;
               margin-top: 6px;
               border-radius: 7px;
               border: $light-border;
               @include transition(background-color 0.2s);
   
               &:not(.disabled):hover {
                  cursor: pointer;
                  background-color: $secondary-color-95-transparant;
               }
   
               &.disabled {
                  opacity: .5;
               }
   
               svg {
                  position: absolute;
                  left: 20px;
                  top: 50%;
                  width: 14px;
                  height: 14px;
                  fill: $secondary-color;
                  @include transform(translateY(-50%));
               }
   
            }
   
            input {
               position: absolute;
               top: -2000px;
               opacity: 0;
               height: 1px;
               width: 1px;
            }
   
         }

         #privacy-note {
            
            display: flex;
            align-items: center;
            padding: 12px 16px;
            box-sizing: border-box;
            margin-bottom: 12px;
            font-size: .8rem;
            background: $secondary-color-90-transparant;
            border-radius: 12px;

            svg {
               height: 1.15em;
               width: 1.15em;
               margin-right: 10px;
               fill: $secondary-color-50-transparant;
            }

         }
   
         .checkbox-row {
            
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 0 -2px 3px;
   
            & > span:first-child {
               flex: 0 0 100%;
               padding-top: 10px;
               margin: 10px 2px 2px;
               border-top: 1px solid $secondary-color-90-transparant;
               font-size: .65rem;
               color: $secondary-color;
            }
            
            .two-inputs {
               
               display: flex;
               width: 100%;
               margin-bottom: 3px;

               & > label {
                  
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 100%;
                  flex: 0 0 36px;
                  font-size: .7rem;
                  font-weight: 900;
                  font-family: $p-font;
                  text-align: center;
                  color: $secondary-color-50-transparant;
                  background: $secondary-color-80-transparant;
                  border-top-left-radius: 15px;
                  border-bottom-left-radius: 15px;

                  @media screen and (max-width: 400px) {
                     flex: 0 0 30px;
                  }

               }

               .input-container {
                  
                  flex-basis: calc(50% - 18px);

                  @media screen and (max-width: 400px) {
                     flex-basis: calc(50% - 15px);
                  }
                  
                  input[type=text] {
                     padding-left: 32px;
                     border-top-left-radius: 0;
                     border-bottom-left-radius: 0;
                  }

                  &:nth-child(2) {

                     input[type=text] {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        border-right: none;
                     }
   
                  }

                  .placeholder {
                     left: 34px;
                  }

                  .icon {
                     left: 13px;
                  }

               }

            }
   
         }
   
         .checkboxes {
            
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            
            & > * {
   
               flex: 0 0 calc(50% - 5px);
   
               @media screen and (max-width: 572px) {
                  flex: 0 0 100%;
               }
   
               label {
                  padding-top: 11px;
                  padding-bottom: 11px;
                  box-sizing: border-box;
                  width: 100%;
               }
   
            }
   
            h4 {
               
               flex: 0 0 100%;
               margin-top: 10px;
               font-size: .7rem;
               text-transform: uppercase;
   
               &:first-of-type {
                  margin-top: 0;
               }
   
            }
   
         }
   
         .two-inputs {
            
            position: relative;
            display: flex;
            justify-content: space-between;
   
            & > * {
               flex: 0 0 calc(50% - 2px);
            }
   
         }

         #edit-form-not-loggedin {
            padding: 15px 20px;
            background: rgb(235, 235, 235);
            border-radius: 20px;
         }
   
      }

   }

   #manage-buttons {
   
      display: flex;
      justify-content: space-between;
      position: fixed;
      right: 50%;
      bottom: 10px;
      z-index: 9999;
      width: 290px;
      max-width: calc(100vw - 20px);
      padding: 8px;
      box-sizing: border-box;
      background: rgb(255, 255, 255);
      border-radius: 20px;
      border: $light-border-thin;
      box-shadow: $bottom-shadow;
      @include transform(translateX(50%));

      @media screen and (max-width: 572px) {
         width: calc(100vw - 20px);
      }

      .update-status {

         align-items: stretch;
         flex: 0 0 50px;

         input {
            position: absolute;
            height: .1px;
            width: .1px;
            opacity: 0;
            visibility: hidden;
         }

         & > label {

            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            background: rgb(255, 255, 255);
            border-radius: 15px;
            box-sizing: border-box;
            border:$light-border;
            cursor: pointer;
            @include transition(background-color 0.2s);

            svg {
               position: absolute;
               height: 16px;
               top: 50%;
               left: 50%;
               fill: $error-color;
               @include transform(translate(-50%, -50%));
            }

         }

         #update-status-pause {

            svg {
               fill: $secondary-color;
            }

            & > span {
               
               display: block;
               position: absolute;
               left: 0;
               right: 0;
               bottom: 4px;
               text-align: center;
               font-size: .52rem;
               text-transform: uppercase;
               color: $secondary-color-50-transparant;

               #live-indicator {
                  
                  display: inline-block;
                  height: .3rem;
                  width: .3rem;
                  margin-right: 3px;
                  border-radius: 50%;
                  background: $secondary-color-50-transparant;
                  animation: flash 2s infinite;

                  @keyframes flash {
                     0% {
                        opacity: 0;
                     }
                     50% {
                        opacity: 1;
                     }
                     100% {
                        opacity: 0;
                     }
                  }

               }

            }

         }

         &.delete {

            span {
               position: absolute;
               left: 45px;
               top: 50%;
               font-size: .85rem;
               color: $error-color;
               @include transform(translateY(-50%));
            }

         }

      }

      #manage-offerings {
         
         flex: 0 0 calc(100% - 110px);

         a {
            padding-left: 18px;
            padding-right: 18px;
         }

      }

      .btn-container {
         min-width: 150px;
      }

      a, label {
         pointer-events: none;
         opacity: 0;
         @include transition(opacity 0.2s);
      }

      svg {
         opacity: 0;
         @include transition(opacity 0.2s);
      }


      &.show {

         a, label {

            pointer-events: all;
            opacity: 1;

            & + svg, svg {
               opacity: 1;
            }
            
         }

      }

      &.delete {

         #manage-offerings, .update-status.status {

            overflow: hidden;
            @include transform(scaleX(0));

         }

         .update-status.delete {

            flex: 0 0 100%;

            svg {
               left: 25px;
            }
            
            label {
               cursor: default;
            }

            div {

               position: absolute;
               right: 20px;
               top: 50%;
               font-size: .85rem;
               color: rgb(30, 30, 30);
               @include transform(translateY(-50%));

               label {

                  cursor: pointer;

                  &:first-child {
                     margin-right: 12px;
                     padding-right: 12px;
                     font-weight: 700;
                     border-right: 1px solid transparentize($color: $secondary-color, $amount: 0.7);
                  }
                  
               }
   
            }

         }

      }

   }

}