@import '../variables.scss';

.rating {

   display: flex;
   align-items: center;

   .rating-score {

      height: 18px;
      margin-left: -2px;

      .rating-half {
      
         display: inline-block;
         height: 10px;
         width: 5px;
         border-radius: 0 16px 16px 0;
         border: 1px solid $secondary-color-50-transparant;
         border-left-width: 0;
         @include transition(background-color 0.2s);
   
         &.left-half {
            margin-left: 2px;
            border-radius: 16px 0 0 16px;
            border-left-width: 1px;
            border-right: none;
         }
         
         &.full {
            background: $secondary-color-50-transparant;
         }
   
      }

   }

   &.input {

      .rating-score {

         pointer-events: none;

         .rating-half {
            
            pointer-events: auto;
            cursor: pointer;
   
         }

         &:hover {

            .rating-half {

               &:not(.full) {
                  background: $secondary-color-80-transparant;
               }

               &:hover ~ * {
                  background: transparent;
               }

            }

         }

      }

   }

   & > span {
      
      margin-left: 6px;
      font-family: $p-font;
      font-weight: 900;
      color: rgb(255, 255, 255);
      font-size: 12px;

      span {
         display: inline-block;
         font-weight: 600;
         color: $secondary-color-50-transparant;
      }

   }

   &.invert {

      display: flex;

      .rating-half {
         border-color: $secondary-color-50-transparant;
      }

      .full {
         background: $secondary-color;
      }

      & > span {
         color: $secondary-color;
      }

   }

   & + .error-message {

      font-size: .65rem;
      margin-top: -12px;
      color: $error-color;

   }

}