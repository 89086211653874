@import '../variables.scss';

#admin-panel {

   & > div + h3 {
      margin-top: 25px;
   }

   #admin-panel-mobile {

      @media screen and (min-width: 972px) {
         display: none;
      }

      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      background: $secondary-color-80-transparant;
      margin-bottom: 20px;
      border-radius: 12px;

      p {
         flex: 0 0 calc(100% - 34px);
         color: $secondary-color;
         font-size: .9rem;
         font-weight: 600;
      }

      svg {
         height: 20px;
         width: 20px;
         fill: $secondary-color;
      }

   }

   #add-users {

      max-width: auto;
      width: min(100%, 300px);
      margin-bottom: 10px;

   }

   #admin-panel-organizations {

      display: flex;
      flex-wrap: wrap;

      .input-container {
         
         flex: 0 0 100%;
         margin-bottom: 8px;
         margin-right: -4px;

         input {
            border-width: 1px;;
            border-radius: 16px;
         }

         @media screen and (max-width: 572px) {
            margin-right: 0;
         }

      }

      & > p {
         color: rgb(180, 180, 180);
      }

      .organization {
         
         flex: 0 0 calc(25% - 4px);

         @media screen and (max-width: 972px) {flex: 0 0 calc(33.33% - 4px);}
         @media screen and (max-width: 768px) {flex: 0 0 calc(50% - 4px);}
         @media screen and (max-width: 572px) {flex: 0 0 100%;}

         position: relative;
         padding: 25px 105px 25px 25px;
         margin-right: 4px;
         box-sizing: border-box;
         margin-bottom: 10px;
         border: $light-border;
         border-radius: 16px;
         border-width: 1px;
         background: rgb(255, 255, 255);
         cursor: pointer;

         h4 {
            font-size: 1rem;
         }

         span {
            display: block;
            font-size: .85rem;
         }
         
         svg {
            position: absolute;
            height: 60px;
            width: 60px;
            right: 20px;
            top: 50%;
            padding: 20px;
            box-sizing: border-box;
            border-radius: 12px;
            background: $secondary-color-80-transparant;
            fill: $secondary-color-50-transparant;
            @include transform(translateY(-50%));
         }

         .organization-payment {

            display: inline-block;
            padding: 5px 10px;
            margin-top: 8px;
            border-radius: 8px;
            color: $secondary-color;
            background: $secondary-color-90-transparant;

         }

         &.add {

            display: flex;
            align-items: center;
            border-color: rgb(230, 230, 230);
            border-style: dashed;

            h4 {
               color: rgb(150, 150, 150);
            }

            svg {
               background: rgb(230, 230, 230);
               fill: rgb(200, 200, 200);
            }

         }

      }

   }

   #settle-bookings {

      margin-bottom: 10px;

      input[type=submit] {
         max-width: 100%;
         width: 300px;
      }

   }

   #admin-panel-edit-user, #admin-panel-edit-org, #admin-panel-add-users {
      
      position: fixed;
      top: 150px;
      right: 12px;
      bottom: 12px;
      width: calc(100vw - 36px);
      max-width: 800px;
      box-sizing: border-box;
      z-index: 999;
      background: rgb(255, 255, 255);
      border: $light-border;
      border-radius: 30px;
      box-shadow: $bottom-shadow-strong;
      opacity: 1;
      animation: fade-in 0.3s;
      overflow-y: auto;
      @include transition(transform 0.3s);

      @keyframes fade-in {
         0% {
            transform: translateX(100%);
         }
         100% {
            transform: translateX(0);
         }
      }

      &.fading-out {
         transform: translateX(100%);
         pointer-events: none;
      }

      .two-inputs {
         
         display: flex;
         justify-content: space-between;
         flex-wrap: wrap;

         .error-message {
            margin-top: 3px;
         }

         & > div {

            flex: 0 0 calc(50% - 3px);
            max-width: 100%;

            @media screen and (max-width: 768px) {
         
               flex: 0 0 100%;
               margin-bottom: 8px;
   
            }

         }

      }

      .form-container {
         
         border: none;

         .form-buttons {

            margin-top: 25px;

            .btn-container {
               width: 500px;
               max-width: calc(50% - 5px);
            }

         }

      }

   }

   .admin-queries {
      
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-right: -10px;

      @media screen and (max-width: 972px) {
         margin-right: 0;
      }

      & > .btn-container {
         flex: 0 0 calc(33.33% - 10px);
      }

      form {
         
         flex: 0 0 calc(33.33% - 10px);

         .form-buttons {
            
            width: 100%;

            .btn-container {
               width: 100%;
               margin-bottom: 10px;
            }

         }

         @media screen and (max-width: 972px) {
            flex: 0 0 100%;
         }

      }

   }

   .responsive-table {

      .booking-forms {

         display: flex;
         align-items: center;
         justify-content: end;
   
         svg {
            
            display: block;
            border: $light-border;
            border-radius: 10px;
            margin-left: 5px;
            padding: 10px;
            width: 34px;
            height: 34px;
            height: 100%;
            box-sizing: border-box;
            cursor: pointer;
            fill: $secondary-color;

         }

      }


   }

}