@import '../../variables.scss';


.input-container {

   position: relative;

   input[type=text], input[type=email], input[type=password], input[type=number], textarea {
   
      position: relative;
      width: 100%;
      padding: 16px 20px;
      box-sizing: border-box;
      font-family: $p-font;
      font-size: .8rem;
      outline: none;
      border:$light-border;
      border-radius: 15px;
      background: rgb(255, 255, 255);
      @include transition(background-color 0.2s, padding 0.2s, border-color 0.3s);
   
      &::placeholder {
         color: transparent;
      }
   
      &:hover, &:focus {
         background: rgb(251, 251, 251);
      }
   
      &:focus, &.not-empty {
         padding: 23px 20px 9px;
      }

      &.error {
         border-color: $error-color;
      }

      @media screen and (max-width: 572px) {
         font-size: .9rem;
      }

      &.readonly {
         background: rgb(243, 243, 243);
         pointer-events: none;
      }
   
   }

   input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }

   input[type=number] {
      -moz-appearance: textfield;
   }

   &.large {

      input[type=text], input[type=email], input[type=password], input[type=number], textarea {

         padding: 20px 22px;
         font-size: .85rem;

         @media screen and (max-width: 572px) {
            font-size: .95rem;
         }

         &:focus, &.not-empty {

            padding: 28px 22px 12px;

         }

      }

   }

   input:focus {

      &::placeholder {
         color: rgb(100, 100, 100)
      }

   }

   &.with-icon, &.large.with-icon {

      input[type=text], input[type=email], input[type=password], input[type=number] {
         padding-left: 38px;
      }

   }

   .icon {
      
      position: absolute;
      top: 50%;
      left: 17px;
      pointer-events: none;
      z-index: 2;
      @include transform(translateY(-50%));

      svg {

         height: .7rem;
         width: .7rem;
         fill: $secondary-color-50-transparant;

         @media screen and (max-width: 572px) {
            height: .75rem;
            width: .75rem;
         }

      }

      @media screen and (max-width: 572px) {
         left: 15px;
         @include transform(translateY(-48%));
      }

   }

   .valid-check {
      
      position: absolute;
      opacity: 0;
      pointer-events: none;
      right: 10px;
      top: 50%;
      height: 12px;
      fill: rgba(0, 150, 0);
      @include transition(transform 0.2s, opacity 0.2s);
      @include transform(translateY(calc(-50% - 3px)));

   }

   &.valid {

      input ~ .valid-check {
         opacity: 1;
         @include transform(translateY(-50%));
      }

   }

   .show-password {

      position: absolute;
      right: 5px;
      top: 50%;
      padding: 5px;
      fill: rgb(50, 50, 50);
      cursor: pointer;
      @include transform(translateY(-50%));

      svg {
         height: 16px;
      }

   }

   textarea {
      resize: none;
   }

   .placeholder {
   
      display: block;
      position: absolute;
      top: 50%;
      left: 20px;
      font-size: .75rem;
      color: rgb(147, 147, 147);
      font-family: $p-font;
      pointer-events: none;
      transform-origin: 0 0;
      @include transform(translateY(-50%));
      @include transition(font-size 0.2s, transform 0.2s, color 0.2s);

      .required {
         margin-left: 2px;
         color: $error-color;
      }

      @media screen and (max-width: 572px) {
         font-size: .8rem;
      }

   }

   &.large {

      .placeholder {
         left: 22px;
         font-size: .9rem;
      }

   }

   &.with-icon {

      .placeholder {
         left: calc(28px + .7rem);
      }
      
   }

   textarea + .placeholder {
      top: 16px;
      left: 20px;
      padding: 12px 20px;
      margin: -12px -15px;
      background: radial-gradient(rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0));
      @include transform(translateY(0));
   }

   textarea:focus + .placeholder, textarea.not-empty + .placeholder {
      color: $secondary-color;
      @include transform(translateY(calc(-.5rem)) scale(0.8));
   }

   input:focus + .placeholder, input.not-empty + .placeholder {
      display: block;
      color: $secondary-color;
      @include transform(translateY(calc(-50% - .5rem)) scale(0.8));
   }

   & + .error-message {
      height: 1.3em;
      margin: -3px 0 10px;
      font-size: .65em !important;
      color: $error-color;
      animation: grow-error 0.2s;
      overflow: hidden;
   }

   @keyframes grow-error {
      0% {
         height: 0;
         margin: 0;
         opacity: 0;
      }
      100% {
         height: .65em;
         margin: -5px 0 12px;
         opacity: 1;
      }
   }

   & ~ .password-requirements {

      margin: -3px 0 10px;

      span {
         
         font-size: .6rem;
         padding: 3px 5px;
         margin-right: 2px;
         border-radius: 7px;
         font-weight: 700;
         color: $error-color;
         border: 2px solid transparentize($error-color, 0.5);
         background: rgba(225, 0, 0, .05);
         @include transition(background-color 0.2s, color 0.2s, border-color 0.2s);

         &.satisfied {
            color: $secondary-color;
            border: $light-border;
            background: rgba(0, 150, 0, .02);
         }

      }

   }

   .max-length {
      
      position: absolute;
      bottom: 7px;
      right: 10px;
      font-size: .65rem;
      opacity: 0;
      @include transform(translateY(4px));
      @include transition(opacity 0.2s, transform 0.2s, color 0.2s);

      &.focused {
         opacity: 1;
         @include transform(translateY(0));
      }

      &.almost-full {
         color:$error-color;
      }

   }

}