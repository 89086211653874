@import '../variables.scss';

#are-you-sure-wrapper {

   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: transparentize(black, 0.5);
   pointer-events: none;
   opacity: 0;
   z-index: 999;

   &.open {
      opacity: 1;
      pointer-events: all;
   }

   #are-you-sure {

      position: absolute;
      top: 50%;
      left: 50%;
      width: 500px;
      padding: 35px;
      max-width: 90vw;
      border: $light-border;
      border-radius: 16px;
      background: rgb(255, 255, 255);
      @include transform(translate(-50%, -50%));
   
      h3 {
         margin-bottom: 15px;
      }
   
      p {
         
         margin-bottom: 25px;
   
      }
   
      .buttons {
         
         display: flex;
         margin-top: 0 !important;
         padding-top: 0 !important;
         gap: 10px !important;

         .btn-container {
            box-sizing: border-box;
            flex: 0 0 calc(50% - 5px);
         }

      }
   
   }

}