@import '../variables.scss';

#welcome {

   #hero {

      padding: 170px 0 90px;
      margin-bottom: 150px;
      background: linear-gradient(
         transparentize(adjust-color($secondary-color, $red: -20, $blue: -20, $green: -20), 0.92) 20%, 
         transparentize(adjust-color($secondary-color, $red: -20, $blue: -20, $green: -20), 0.9) 100%);  

      #hero-tagline {

         width: 50%;
         padding-left: 30px;

         h1 {
         
            font-size: 2.7rem;
            line-height: 3.2rem;
   
            span:not(.stress) {
               color: $primary-color;
            }
   
         }

         p {
            padding-right: 50px;
            box-sizing: border-box;
            margin-bottom: 20px;
            line-height: 1.5em;
            font-size: 1.2rem;
         }

         @media screen and (max-width: 972px) {

            width: 100%;
            max-width: 700px;
            padding: 0 40px;
            box-sizing: border-box;

            h1, p {
               color: rgb(255, 255, 255);
            }

            p {
               font-weight: 700;
               font-size: 1.4rem;
            }

         }

      }

      .container {
         position: relative;
      }

      #hero-image {

         position: absolute;
         left: 52%;
         top: -120px;
         width: 50%;
         min-width: 500px;
         max-width: 800px;
         z-index: -1;

         img {
            display: block;
            width: 100%;
            height: 420px;
            border-radius: 0 0 10px 10px;
            object-fit: cover;
            object-position: 50% 70%;
         }

         @media screen and (max-width: 1200px) {
            
            img {
               height: 450px;
            }

            @media screen and (max-width: 972px) {

               left: -15px;
               width: calc(100% + 30px);
               max-width: none;
               background: $secondary-color;

               img {
                  border-radius: 0;
                  opacity: .2;
                  height: 370px;
                  object-position: 50% 40%;
               }

               @media screen and (max-width: 572px){
                  img {
                     height: 450px;
                  }
               }

            }

         }

      }

   }

   #info {

      max-width: 1000px;
      padding-top: 50px;

      .row {
       
         position: relative;
         display: flex;
         align-items: center;
         margin-bottom: 120px;
         flex-direction: row-reverse;

         &.highlight {

            padding: 40px 0;
            background: $secondary-color-90-transparant;
            border-radius: 20px;

            & > span {
               position: absolute;
               left: 50%;
               top: 0;
               padding: 10px;
               background: $secondary-color;
               color: rgb(255, 255, 255);
               text-transform: uppercase;
               font-weight: 900;
               font-size: .6rem;
               border-radius: 8px;
               @include transform(translate(-50%, -50%));
            }

            @media screen and (max-width: 572px) {
               padding: 40px 20px;
            }

         }

         &:nth-child(2) {
            flex-direction: row;
            text-align: right;
         }

         & > div {

            flex: 0 0 50%;
            padding: 0 30px;
            box-sizing: border-box;

            h2 {
               margin-bottom: 10px;
               line-height: 1.3em;
               font-size: 1.8rem;
            }

            p {
               line-height: 1.5em;
            }

            img {
               
               width: 100%;
               height: 280px;
               object-fit: cover;
               border-radius: 10px;

            }

            
            @media screen and (max-width: 768px) {
               
               padding: 0 15px;

               &.image {
                  
                  flex-basis: 35%;

                  img {
                     width: 300px;
                     object-position: 50% 50%;
                  }

               }

               &.text {
                  flex-basis: 65%;
               }

               @media screen and (max-width: 572px) {
                  
                  padding: 0 10px;

                  &.image {

                     img {
                        height: 320px;
                     }

                  }

               }

            }

         }

         &:first-of-type {

            img {
               object-position: 50% 20%;
            }
   
         }

      }

   }

   #welcome-finish {

      margin-top: 250px;
      padding: 100px 0 250px;
      background: $secondary-color-90-transparant;

      .container {

         max-width: 550px;

         .form-container {

            margin-top: -200px;
            padding: 35px;

            h3 {
               margin-bottom: 10px;
            }
   
            p {
               font-size: 1rem;
               margin-bottom: 20px;
            }
   

            form {

               .btn-container {
                  max-width: 100%;
               }

            }

         }

      }

      #welcome-finish-waves {

         position: relative;
         height: 0;
         width: 100%;
   
         svg {
         
            position: absolute;
            top: -100px;
            left: -20vw;
            @include transform(rotate(180deg) translateX(-150px));
   
            path {
               height: 400px;
               width: 140vw;
               fill: rgba(0, 26, 66, .03);
            }
   
            &:first-child {
               @include transform(rotate(180deg) translateX(-150px) scaleX(-1));
   
            }
   
         }
   
      } 

   }
   
}