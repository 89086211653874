@import './variables.scss';

@font-face {
  font-family: 'Junegull';
  src: local('Junegull'), url(./fonts/junegull.otf) format('opentype');
}

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;900&family=Kalnia:wght@400;600;800&display=swap');

/* resets */
html, body {
  max-width: 100vw;
  min-width: 320px;
  overflow-x: hidden;
}
body {
  margin: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}
div[data-lastpass-icon-root] {
  display: none;
}


h1, h2, h3, h4, h5, h6 {
  
  font-family: $header-font;
  font-weight: $header-font-weight;
  color: $header-color;
  margin: 0;

  .stress {
    // color: $primary-color;
  }

  &.center {
    text-align: center;
  }

  span {
    font-family: $header-font;
  }

}
h1 {
  font-size: 2.5rem;
  line-height: 3rem;
  margin-bottom: 20px;
}

h2 {
  
  font-size: 1.7rem;
  line-height: 1.6rem;

}

h3, h3 span {
  font-family: $p-font;
  font-size: 1.3rem;
}

h2, h3 {

  & + .subtitle {

    font-size: 1.2rem;
    font-family: $p-font;


    &.center {
      text-align: center;
    }

    &.accent {

      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        height: 6px;
        width: 50px;
        background: $primary-color;
        border-radius: 3px;
      }

      &.center:after {
        left: 50%;
        @include transform(translateX(-50%));
      }

    }

  }

}

h4, h4 span {
  font-family: $p-font;
  font-size: 1.1rem;
}

p, a {

  font-family: $p-font;
  text-decoration: none;
  color: $p-color;
  margin: 0;

  .stress {
    color: $cta-color;
  }

  h1, h2, h3, h4, h5, h6 {

    .stress {
      color: $primary-color;
    }

  }

  .inline-tokens {
    
    display: inline-block;
    font-family: $header-font;
    font-weight: 700;
    margin-right: 4px;
    color: $secondary-color !important;

    svg {

      height: 1em;
      width: 1.5em;
      padding-top: 3px;
      box-sizing: border-box;
      margin: 0 0 0 2px;
      fill: $secondary-color !important;

    }

  }

}

p a {
  color: $primary-color;
  text-decoration: underline;
}

span, ul {
  font-family: $p-font;
}


/* layout */
main {
  padding-top: calc(2rem + 60px);
  box-sizing: border-box;
}

.container {
  display: block;
  width: 100vw;
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 1200px) {
    padding: 0 15px;
  }

}

/* backgrounds */
#hero-bg {

  position: absolute;
  top: -250px;
  left: -25vw;
  width: 150vw;
  height: 710px;

  &.small {

    top: -365px;

    @media screen and (max-width: 972px) {
      top: -420px;
    }

  }

  .dark-blue, .light-blue {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .dark-blue {
    margin-top: 10px;
    background: $primary-color;
  }

  .light-blue {
    background: $secondary-color;
    background: linear-gradient(0deg, $secondary-color, 
      adjust-color($secondary-color, $red: -20, $blue: -20, $green: -20));
  }

}


/* tables */
table {
  font-family: $p-font;
}