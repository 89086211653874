@import '../../variables.scss';

.form-container {

   position: relative;
   padding: 25px;
   border-radius: 20px;
   background: rgb(255, 255, 255); 
   border: $light-border;

   @media screen and (max-width: 572px) {
      
      padding: 20px 15px;

   }

   /*
   
   &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 50px;
      background: linear-gradient(100deg, rgba(0, 26, 66, .00), 
         rgba(0, 26, 66, .00) 45%, rgba(0, 26, 66, .05) 45%, 
         rgba(0, 26, 66, .05) 47%, rgba(0, 26, 66, .10) 47%,
         rgba(0, 26, 66, .10) 53%, rgba(0, 26, 66, .05) 53%,
         rgba(0, 26, 66, .05) 55%, rgba(0, 26, 66, .00) 55%, 
         rgba(0, 26, 66, .00) 100%);
      z-index: 0;
      pointer-events: none;
   } 
   */
   
   h3 {

      position: relative;
      display: block;
      margin-bottom: 10px;

   }

   h3 + p {
      margin-bottom: 15px;
   }

   h4 {
      margin-top: 25px;
      margin-bottom: 5px !important;
   }

   form {

      position: relative;

      & > * {
   
         margin-bottom: 5px;
   
         &:last-child {
            margin-bottom: 0;
         }
   
      }

      .btn-container {
         display: block;
         margin: 0 auto;
         width: 100%;
         max-width: 300px;
      }

      &.oneline-form {

         position: relative;

         input[type=text], input[type=email], textarea {
            width: calc(100% - 59px);
         }

         .oneline-submit {
            top: 0;
            left: 0;
            position: absolute;
            opacity: 0;
            height: .1px;
            width: .1px;
            pointer-events: none;
         }

         label {

            position: absolute;
            height: 100%;
            width: 55px;
            top: 0;
            right: 0;
            border-radius: 10px;
            background: $cta-color;
            cursor: pointer;
            @include transition(background-color 0.2s);

            &:hover {
               background: adjust-color($color: $cta-color, $red: -15, $green: -15, $blue: -15);
            }

            &.disabled, &.disabled:hover {
               background: rgb(200, 200, 200);
               pointer-events: none;
            }

            svg {
               position: absolute;
               top: 50%;
               left: 50%;
               height: 14px;
               width: 14px;
               fill: rgb(255, 255, 255);
               @include transform(translate(-50%, -50%));
            }

         }

      }

      .btn-container {
         margin-top: 15px;
      }

      .form-buttons.multiple {
         
         display: flex;
         justify-content: space-between;

         .btn-container {
            
            position: relative;
            width: 300px;
            flex-basis: calc(50% - 2px);
            min-width: auto;
            margin: 10px 0 0;
            z-index: 0;

            @media screen and (max-width: 400px) {

               .btn {
                  
                  padding: 16px;

                  & + svg {
                     right: 14px;
                  }

               }

            }

         }

      }

      .loader {

         position: absolute;
         top: 0;
         left: 0;
         height: 100%;
         width: 100%;
         background: rgba(255, 255, 255, .7);
         z-index: 99999;
   
         .lds-ring {
            position: absolute;
            width: 48px;
            height: 48px;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));
          }
   
          .lds-ring div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 32px;
            height: 32px;
            margin: 8px;
            border: 4px solid $secondary-color;
            border-radius: 50%;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: $secondary-color transparent transparent transparent;
          }
          
          .lds-ring div:nth-child(1) {
            animation-delay: -0.45s;
          }
   
          .lds-ring div:nth-child(2) {
            animation-delay: -0.3s;
          }
   
          .lds-ring div:nth-child(3) {
            animation-delay: -0.15s;
          }
   
          @keyframes lds-ring {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          
         p {
            position: absolute;
            top: calc(50% + 30px);
            left: 50%;
            @include transform(translate(-50%, -50%));
         }

      }
   
   }

 }

