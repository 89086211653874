@import '../../variables.scss';

.select {

   position: relative;
   font-family: $p-font;

   input[type=text] {
      
      width: 100%;
      padding: 16px 40px 16px 20px;
      box-sizing: border-box;
      font-family: $p-font;
      font-size: .75rem;
      outline: none;
      border: $light-border;
      border-radius: 15px;
      background: rgb(255, 255, 255);
      @include transition(background-color 0.2s, padding 0.2s, border-color 0.2s);

      &::placeholder {
         color: rgb(147, 147, 147);
      }

      &:hover {
         background: rgb(251, 251, 251);
      }

      &.not-empty {
         padding: 22px 40px 10px 20px;
      }

      &.not-empty {
         background: rgb(255, 255, 255);
      }

      @media screen and (max-width: 572px) {
         font-size: .9rem;
      }

   }

   input.error + input[type=text] {
      border: 1px solid $error-color;
   }

   &.readonly {
      
      & > input[type=text] {
         cursor: pointer;
      }

   }

   &.disabled {
      
      pointer-events: none;

      & > input[type=text] {
         background: rgb(243, 243, 243);
      }

   }

   & + .error-message {
      height: 1.3em;
      margin: -3px 0 10px;
      font-size: .65em !important;
      color: $error-color;
      animation: grow-error 0.2s;
      overflow: hidden;
   }

   &.with-icon {

      input[type=text] {
         padding-left: 38px;
      }

   }

   &.random {

      input[type=text] {
         width: calc(100% - 60px);
      }

      .chevron {
         right: 75px;
      }

   }

   .options {

      position: absolute;
      top: 110%;
      width: 100%;
      pointer-events: none;
      opacity: 0;
      @include transform(translateY(-5px));
      @include transition(opacity 0.2s, transform 0.2s);

      ul {
      
         padding: 10px 0;
         max-height: 250px;
         font-size: .8rem;
         box-sizing: border-box;
         border: $light-border;
         background: rgb(255, 255, 255);
         border-radius: 15px;
         color: $secondary-color;
         overflow-y: auto;
         z-index: 9999;
         box-shadow: 0 2px 20px rgba(0, 26, 66, .1);

         &.allow-search {
            border-radius: 0 0 10px 10px;
         }

         h4 {
            margin: 0;
            margin-bottom: 1px !important;
            padding: 5px 15px 0;
            font-size: .8rem;
            line-height: .9rem;
            user-select: none;
         }
   
         li {
            
            display: flex;
            justify-content: space-between;
            position: relative;
            padding: 4px 25px;
            cursor: pointer;
            @include transition(background-color 0.25s);
   
            @media screen and (max-width: 768px) {
               padding: 8px 25px;
            }
   
            &:hover, &.selected {
               background: rgb(245, 245, 245);
            }
   
            &.inactive {
               padding-top: 8px;
               color: rgb(128, 128, 128);
               pointer-events: none;
            }
   
            &.selected {
               font-weight: 700;
            }

            .count {
               display: inline-block;
               padding: 3px 3px;
               line-height: 1em;
               font-size: .7rem;
               border-radius: 4px;
               background-color: $secondary-color-80-transparant;
               opacity: .8;
            }
   
         }
   
      }

      .btn-container {
         display: none;
         position: absolute;
         left: 25px;
         bottom: 35px;
         width: calc(100vw - 50px);
         max-width: none;
      }

      @media screen and (max-width: 768px) {

         position: fixed;
         top: 0;
         left: 0 !important;
         width: 100vw !important;
         height: calc(var(--vh, 1vh) * 100);
         padding: 80px 15px 20px;
         box-sizing: border-box;
         opacity: 1;
         pointer-events: none;
         z-index: 99999;
         opacity: 0;
         background: rgba(0, 0, 0, 0.7);

         ul {

            height: calc(var(--vh, 1vh) * 100 - 100px);
            padding-bottom: 70px;
            max-height: none;

            &.allow-search {
               height: calc(var(--vh, 1vh) * 100 - 150px);
            }

         }

         .btn-container {
            display: block;
         }

      }


   }  

   .options {

      .input-container {

         margin-bottom: 0 !important;

         .icon {
            @include transform(translateY(-45%));
         }

         input[type=text] {

            font-size: .7rem;
            padding: 10px 38px;
            border-bottom: none;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            @media screen and (max-width: 768px) {
               padding: 13px 38px;
            }
            
            &:focus, &.not-empty {
               background: rgb(255, 255, 255);
            }

         }

      }

      ul {
      
         user-select: none;
   
         &.max-selected {
   
            li:not(.selected) {
               pointer-events: none;
               opacity: 0.5;
            }
   
         }
   
      }

   }

   &.multi {

      .options {

         ul {

            li {
            
               padding-left: 36px;
               @include transition(opacity 0.2s);
      
               &:before, svg {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: 15px;
                  width: .8em;
                  height: .8em;
                  border-radius: 3px;
                  box-sizing: border-box;
                  border: $light-border;
                  pointer-events: none;
                  @include transition(font-weight 0.2s);
                  @include transform(translateY(-50%));
               }
      
               &.inactive:before, &.inactive svg {
                  display: none;
               }
      
               svg {
                  border: none;
                  opacity: 0;
                  fill: $primary-color;
                  @include transition(opacity 0.25s);
               }
      
               &.selected {
      
                  svg {
                     opacity: 1;
                  }
      
               }
      
            }

         }

      }

   }

   .selected-values {

      display: block;
      position: absolute;
      top: calc(.8rem + 11px);
      left: 38px;
      font-size: .65rem;
      color: $secondary-color;
      pointer-events: none;

      .selected-value {

         display: inline-block;
         padding: 1px 5px;
         margin-right: 2px;
         background: $secondary-color-90-transparant;
         border-radius: 4px;

         &:last-child {
            margin-right: 0;
         }

         &.too-many {
            font-weight: 700;
         }

      }

   }

   &.show {

      .options {
         opacity: 1;
         pointer-events: all;
         @include transform(translateY(0));
      }

      .chevron {
         pointer-events: all;
         cursor: pointer;
         @include transform(translateY(-50%) rotate(-90deg));
      }

   }

   .chevron {
      
      position: absolute;
      top: 50%;
      right: 13px;
      height: .6rem;
      width: .6rem;
      padding: 5px;
      fill: $secondary-color;
      pointer-events: none;
      @include transition(transform 0.2s);
      @include transform(translateY(-50%) rotate(-270deg));

   }

   .icon {
      
      position: absolute;
      top: 50%;
      left: 17px;
      pointer-events: none;
      @include transform(translateY(-50%));

      svg {
         
         height: .7rem;
         width: .7rem;
         fill: $secondary-color-50-transparant;

         @media screen and (max-width: 572px) {
            height: .75rem;
            width: .75rem;
         }

      }

   }

   .placeholder {
      
      position: absolute;
      top: 50%;
      left: 22px;
      font-size: .75rem;
      color: rgb(147, 147, 147);
      pointer-events: none;
      transform-origin: 0 0;
      @include transform(translateY(-50%));
      @include transition(font-size 0.2s, transform 0.2s, color 0.2s);

      @media screen and (max-width: 572px) {
         font-size: .8rem;
      }

   }

   &.with-icon {

      .placeholder {
         left: calc(28px + .7rem);
      }

   }

   input.not-empty + .placeholder {
      display: block;
      color: $secondary-color;
      @include transform(translateY(calc(-50% - .5rem)) scale(0.8));
   }

   .randomize {
      
      position: absolute;
      right: 0;
      top: 0;
      background: $secondary-color;
      border-radius: 10px;
      height: 100%;
      width: 55px;
      cursor: pointer;
      @include transition(background-color 0.2s);

      &:hover {
         background: adjust-color($secondary-color, $red: -15, $blue: -15, $green: -15);
      }

      svg {
         position: absolute;
         top: 50%;
         left: 50%;
         height: 16px;
         width: 16px;
         fill: rgb(255, 255, 255);
         transform: translate(-50%, -50%);
      }

   }

}

.input-label {
   
   display: block;
   font-size: .7rem;
   font-family: $p-font;
   margin-bottom: 3px !important;
   color: $secondary-color;

   .required {
      color: $error-color;
      margin-left: 2px;
   }

}


@media screen and (min-width: 768px) {
   .z-index-select-10 { z-index: 10 !important; }
   .z-index-select-9 { z-index: 9 !important; }
   .z-index-select-8 { z-index: 8 !important; }
   .z-index-select-7 { z-index: 7 !important; }
   .z-index-select-6 { z-index: 6 !important; }
   .z-index-select-5 { z-index: 5 !important; }
   .z-index-select-4 { z-index: 4 !important; }
   .z-index-select-3 { z-index: 3 !important; }
   .z-index-select-2 { z-index: 2 !important; }
   .z-index-select-1 { z-index: 1 !important; }   
}
