@import '../variables.scss';

#calculator-component {

   max-width: 600px;
   padding: 25px 30px;
   box-sizing: border-box;
   border-radius: 10px;
   background: rgb(255, 255, 255);

   h3 {
      padding-bottom: 5px;
   }
   
   & > p {
      padding-bottom: 15px;
   }

   #calculator-input {
      
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $secondary-color-90-transparant;

      & > * {
      
         flex: 0 0 calc(50% - 3px);
         margin-bottom: 6px;

         @media screen and (max-width: 700px) {

            flex: 0 0 100%;
            margin-bottom: 6px;

            &:nth-child(3) {
               margin-bottom: 6px;
            }

         }

      }

      .warning {

         flex: 0 0 100%;
         box-sizing: border-box;
         padding: 14px 16px;
         margin: 0 0 6px;
         border-radius: 7px;
         background: transparentize($error-color, .92);

         p {
            font-size: .75rem;
            color: $error-color;
         }

      }

   }

   #calculator-output {

      .calculator-output-section {

         & > h4 {
            margin-bottom: 3px;
            flex: 0 0 100%;
            font-size: 1.1rem;
         }

         & > p {
            margin-bottom: 12px;
         }

         ul {

            display: flex;
            flex-wrap: wrap;
            align-items: center;

            @media screen and (max-width: 700px) {
               justify-content: center;
            }
   
            li {
               
               flex: 0 0 calc(33.33% - 20px);
               padding: 15px 20px;
               box-sizing: border-box;
               border-radius: 10px;
               color: $secondary-color;
               background: rgb(240, 240, 244);
               text-align: center;

               @media screen and (max-width: 700px) {
                  flex: 0 0 100%;
               }
   
               span {
                  font-size: 1.2rem;
                  font-weight: 700;
               }
   
               h4 {
                  font-size: .9rem;
                  font-weight: 400;
                  line-height: 1.1rem;
               }
   
               &:last-child {
                  margin-bottom: 0;
               }
   
            }
   
            svg {
               
               flex: 0 0 30px;
               padding: 8px;
               box-sizing: border-box;
               fill: $secondary-color;
   
            }
   
         }

         .equals {

            display: block;
            width: 100%;
            font-size: 1.5rem;
            font-weight: 700;
            color: $secondary-color;
            text-align: center;

         }

         .outcome {

            padding: 15px 20px;
            box-sizing: border-box;
            border-radius: 10px;
            color: $secondary-color;
            background: rgb(240, 240, 244);
            border: 2px solid transparentize(rgb(0, 200, 0), 0.5);
            text-align: center;
            background: transparentize(rgb(0, 200, 0), 0.91);

            span {
               font-size: 1.2rem;
               font-weight: 700;
               color: rgb(0, 180, 0);
            }

            h4 {
               font-size: .9rem;
               font-weight: 400;
               line-height: 1.1rem;
               color: rgb(0, 180, 0);
            }

            &.before {

               background: transparentize($error-color, 0.91);
               border-color: transparentize($error-color, 0.6);

               span, h4 {
                  color: rgb(180, 0, 0);
               }
               
               & + h4 {
                  margin-top: 20px;
                  padding-top: 20px;
                  border-top: 1px solid $secondary-color-90-transparant;
               }

            }

         }

      }

   }

}