@import '../variables.scss';

nav {

   position: absolute;
   top: 0;
   left: 0;
   width: 100vw;
   z-index: 999999;

   @media screen and (max-width: 768px) {
      position: fixed;
   }  

   #nav-bar {

      display: flex;
      position: absolute;
      left: 0;
      width: 100vw;
      top: -100px;
      padding-right: 15px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: rgb(255, 255, 255);
      border-bottom: $light-border-thin;
      // box-shadow: $bottom-shadow;
      @include transition(background-color 0.4s, box-shadow 0.4s, top 0.3s);

      @media screen and (max-width: 768px) {
         padding-right: 0;
      }

      &.show-bar {
         top: 0;
      }

      &.scrolled {
         
         background: rgb(255, 255, 255);
         box-shadow: 0 5px 0 rgba(0, 0, 0, 0.12);
         backdrop-filter: blur(10px);

         #nav-bar-links .nav-list span {
            color: $secondary-color;
         }

      }

      #nav-bar-elements {   
      
         display: flex;
         justify-content: space-between;
         margin: 0 auto;
         width: 100%;
         max-width: 1200px;

         &.no-links {

            justify-content: center;

            #nav-logo {
               
               padding: 26px 0;

               @media screen and (max-width: 768px) {
                  padding: 0;
               }

            }

         }

      }

      #nav-logo {

         position: relative;
         display: flex;
         align-items: center;

         @media screen and (max-width: 1200px) {
            padding-left: 14px;
         }

         @media screen and (max-width: 768px) {
            padding-top: 4px;
            padding-bottom: 4px
         }

         svg {
            
            position: relative;
            display: block;
            height: auto;
            width: 130px;
            z-index: 1;

            @media screen and (max-width: 768px) {
               height: 45px;
            }

         }

      }

      #nav-bar-links {

         position: relative;
         display: flex;
         align-items: center;

         @media screen and (max-width: 768px) {
            display: none;
         }   

         .nav-list {
            
            display: block;
            padding-left: 24px;
            cursor: pointer;

            span {
               
               position: relative;
               display: block;
               padding: 50px 10px;
               margin: -29px -5px;
               font-size: .9rem;
               font-weight: 600;
               color: $secondary-color;
               z-index: 1;
               @include transition(color 0.4s);

               svg {
                  height: .5em;
                  fill: $secondary-color;
                  @include transform(rotate(90deg));
               }

               &:after {
                  content: '';
                  position: absolute;
                  left: -7px;
                  right: -7px;
                  top: 37px;
                  bottom: 37px;
                  background: $secondary-color-90-transparant;
                  opacity: 0;
                  border-radius: 15px;
                  z-index: -1;
                  @include transform(scale(0));
                  @include transition(opacity 0.2s, transform 0.2s);
               }

            }

            &:hover span::after {
               opacity: 1;
               @include transform(scale(1));
            }
            
            div {
               
               display: flex;
               align-items: flex-start;
               flex-wrap: wrap;
               position: absolute;
               top: calc(100% + 10px);
               right: 0;
               width: min(1000px, calc(100vw - 60px));
               padding: 5px 15px 20px;
               pointer-events: none;
               border-radius: 20px;
               border: $light-border-thin;
               background: rgb(255, 255, 255);
               opacity: 0;
               @include transition(opacity 0.2s, transform 0.2s);
               @include transform(translateY(-10px));

               &.length-1 {
                  width: 200px;
                  ul {flex: 0 0 100%;}
               }

               &.length-2 {
                  width: 400px;
                  ul {flex: 0 0 50%;}
               }

               &.length-3 {
                  width: 600px;
                  ul {flex: 0 0 33.33%;}
               }

               &.length-4 {
                  width: min(800px, calc(100vw - 60px));
                  ul {flex: 0 0 25%;}
               }

               @media screen and (max-width: 972px) {
                  width: min(800px, calc(100vw - 60px));
               }

               ul {
                  
                  margin-top: 15px;
                  flex: 0 0 20%;

                  @media screen and (max-width: 972px) {
                     flex: 0 0 25%;
                  }

                  input[type=checkbox] {
                     position: absolute;
                     height: .1px;
                     width: .1px;
                     opacity: 0;
                     visibility: hidden;
                     top: -1000px;
                  }

                  a, label {
   
                     position: relative;
                     display: block;
                     padding: 2px 10px;
                     box-sizing: border-box;
                     font-size: .8rem;
                     line-height: 1.4rem;
                     cursor: pointer;
                     color: $secondary-color;
                     border-radius: 10px;
                     @include transition(background-color 0.2s);
   
                     &:hover {
                        background: $secondary-color-95-transparant;
                     }
   
                     &:last-of-type {
                        margin-bottom: 0;
                     }
   
                  }

                  label {

                     padding-top: 0;
                     margin-bottom: 3px;
                     font-weight: 900;

                     &:hover {
                        background: transparent;
                     }

                     svg {
                        display: none;
                     }

                  }
   
               }

            }

            &.show div {

               pointer-events: all;
               opacity: 1;
               @include transform(translateY(0));

            }

         }

         #profile-link {

            position: relative;
            padding: 0 15px 30px;
            margin: 0 -15px 0 5px;
            cursor: pointer;
            @include transform(translateY(15px));

            #profile-navbar {
               
               display: flex;
               align-items: center;
               padding: 6px 6px 6px 16px;
               border: $light-border;
               border-radius: 20px;
               @include transition(background-color 0.2s);

               span {
                  color: $secondary-color;
                  font-size: .9rem;
                  font-weight: 600;
                  margin-right: 16px;
               }

               svg {
               
                  height: 28px;
                  width: 28px;
                  padding: 4px;
                  box-sizing: border-box;
                  fill: $secondary-color;
                  border-radius: 50%;
                  background: $secondary-color-90-transparant;

               }

            }
         
            &:hover #profile-navbar {
               background: transparentize($secondary-color-95-transparant, .3);
            }

            #profile-info {
               
               position: absolute;
               top: calc(100% - 5px);
               right: 15px;
               width: 240px;
               box-sizing: border-box;
               padding: 20px 25px;
               background: rgb(255, 255, 255);
               opacity: 0;
               border: $light-border-thin;
               border-radius: 20px;
               pointer-events: none;
               cursor: default;
               @include transition(opacity 0.2s, transform 0.2s);
               @include transform(translateY(-10px));


               &.show {
                  opacity: 1;
                  pointer-events: all;
                  @include transform(translateY(0));
               }

               h4 {
                  
                  font-size: .9rem;
                  padding-bottom: 10px;

                  & + a {
                     margin-top: 10px;
                  }

               }

               #profile-tokens, #profile-bits {

                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 100%;
                  padding: 8px 12px;
                  box-sizing: border-box;
                  margin-bottom: 3px;
                  border-radius: 18px;
                  font-weight: 900;
                  font-size: .8rem;
                  font-family: $p-font;
                  color: $secondary-color;
                  background: $secondary-color-90-transparant;
                  cursor: pointer;

                  svg {
                     display: inline-block;
                     box-sizing: border-box;
                     height: 1em;
                     width: 1em;
                     fill: $secondary-color;
                  }

               }

               #profile-bits {
                  
                  margin-bottom: 12px;
                  background: $wellbits-color-80-transparant;
                  color: $wellbits-color;

                  svg {
                     height: 1.2em;
                     width: 1.2em;
                     fill: $wellbits-color;
                  }

               }

               a {
                  
                  display: block;
                  position: relative;
                  width: 100%;
                  font-size: .8rem;
                  padding: 10px 16px;
                  box-sizing: border-box;
                  border-radius: 10px;
                  @include transition(background-color 0.2s);

                  &#profile-logout {
                     
                     color: $error-color;

                     &:hover {
                        background: transparentize($error-color, .9);
                     }

                  }

                  &:hover {
                     background: $secondary-color-95-transparant;
                  }

                  &#profile-link-signup {
                     font-weight: 900;
                     border-top: none;
                  }

               }

            }

         }

      }

      #nav-window-mobile {
         
         position: absolute;
         display: none;
         left: 100vw;
         top: 0;
         height: 100vh;
         padding-bottom: 30px;
         width: 100vw;
         max-width: 500px;
         background: rgb(255, 255, 255);
         z-index: 9999999;
         box-shadow: $left-shadow;
         overflow-y: scroll;
         box-sizing: border-box;
         @include transition(left 0.4s);

         &.shown {
            left: max(100vw - 100vw, 100vw - 500px);

            #nav-window-mobile-close {
               pointer-events: all;
            }

         }

         #nav-window-mobile-close {

            position: absolute;
            top: 0;
            right: 100%;
            height: 100vh;
            width: 100vw;
            pointer-events: none;

         }

         #nav-window-mobile-arrow {
            position: absolute;
            top: 10px;
            left: 10px;
            height: 30px;
            width: 30px;
            padding: 5px;
            fill: $secondary-color;
            cursor: pointer;
         }

         #nav-window-mobile-buttons {

            display: flex;
            flex-direction: row-reverse;
            width: 100%;
            padding: 28px 28px 24px;
            box-sizing: border-box;

            a {

               position: relative;
               margin-left: 16px;
               text-align: center;
               cursor: pointer;
   
               svg {

                  height: 20px;
                  width: 20px;
                  fill: $secondary-color;
   
                  &:nth-child(2) {
                   
                     position: absolute;
                     height: 22px;
                     width: 22px;
                     left: 20px;
                     opacity: 0;
                     @include transition(transform 0.2s, opacity 0.2s);
                     @include transform(scale(0));
   
                  }
   
                  &:first-child:hover + svg, &:nth-child(2):hover {
                     opacity: .2;
                     @include transform(scale(1));
                  }
   
               }
   
               span {
                  display: block;
                  font-size: .6rem;
                  text-align: center;
                  color: $secondary-color;
               }

               &:first-child {

                  span {
                     color: rgb(150, 150, 150);
                  }

                  svg {
                     fill: rgb(150, 150, 150);
                  }

               }
   
            }

         }

         #nav-window-tokens-mobile, #nav-window-bits-mobile {

            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px;
            margin: 0 28px 5px;
            border-radius: 4px;
            font-weight: 900;
            font-size: .8rem;
            font-family: $p-font;
            color: $secondary-color;
            background: $secondary-color-90-transparant;
            cursor: pointer;

            svg {
               display: inline-block;
               box-sizing: border-box;
               height: 1em;
               width: 1.1em;
               fill: $secondary-color;
            }

         }

         #nav-window-bits-mobile {

            margin-bottom: 29px;
            background: $wellbits-color-80-transparant;
            color: $wellbits-color;

            svg {
               height: 1.2em;
               width: 1.2em;
               fill: $wellbits-color;
            }

         }

         .nav-list {

            box-sizing: border-box;
            padding: 30px 28px;
            border-top: 1px solid $secondary-color-90-transparant;

            ul {

               a, label {
   
                  position: relative;
                  display: block;
                  padding: 10px 0;
                  min-width: 150px;
                  font-size: .85rem;
                  cursor: pointer;
                  color: $secondary-color;
   
                  &:last-of-type {
                     padding-bottom: 0;
                  }

               }

               label {

                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  padding: 10px 0 !important;

                  & ~ a {
                     
                     display: none;
                     padding: 8px 12px;
                     background: $secondary-color-90-transparant;

                     &:first-of-type {
                        padding-top: 12px;
                        border-radius: 10px 10px 0 0;
                     }

                     &:last-child {
                        margin-bottom: 15px;
                        padding-bottom: 12px;
                        border-radius: 0 0 10px 10px;
                     }

                  }
            
                  svg {
                     fill: $secondary-color;
                     height: 12px;
                     width: 12px;
                     @include transform(rotate(90deg));
                  }

                  &:last-child {
                     padding-bottom: 0;
                  }

               }

               input[type=checkbox] {
               
                  position: absolute;
                  height: .1px;
                  width: .1px;
                  opacity: 0;
                  visibility: hidden;
                  right: -1000px;
   
               }

               &.show {
                  
                  label {
                     
                     svg {
                        @include transform(rotate(-90deg));
                     }

                     & ~ a {
                        display: block;
                     }

                  }

               }

               &:last-child {

                  label {
                     margin-bottom: 0;
                  }

               }
   
            }
   
            span {
                  
               display: block;
               margin-bottom: 10px;
               font-family: $p-font;
               font-size: .85rem;
               color: $secondary-color;
               font-weight: 900;
               color: $secondary-color;
               text-transform: uppercase;
               @include transition(color 0.4s);
   
               svg {
                  display: none;
               }
   
            }

            &#nav-list-logout {

               a {

                  padding-top: 0;

                  li {
                     color: $error-color;
                  }

               }

            }

         }

         @media screen and (max-width: 768px) {
         
            display: block;

         }  
         
         #nav-window-mobile-footer {

            padding-top: 28px;
            border-top: 1px solid $secondary-color-90-transparant;

            svg {
               display: block;
               margin: 0 auto 20px;
               height: 20px;
               width: 110px;
            }

         }

      }

      #nav-window-mobile-show {
         
         display: none;
         height: 24px;
         width: 24px;
         margin-right: 14px;
         fill: $secondary-color;
         cursor: pointer;

         @media screen and (max-width: 768px) {
         
            display: block;

         } 

      }

   }

}