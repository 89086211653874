@import '../variables.scss';

#partners {

   @keyframes fade-in-element {
      0% {
         opacity: 0;
         @include transform(translateY(-10px));
      }
      100% {
         opacity: 1;
         @include transform(translateY(0));
      }
   }

   #hero {

      position: relative;
      margin: 0px 30px 0;
      height: calc(100 * var(--vh, 1vh) - 150px);
      min-height: 700px;
      max-height: 1200px;
      box-sizing: border-box;
      border-radius: 30px;
      animation: fade-in-no-translate 1s forwards;

      &:after {
         content: '';
         position: absolute;
         top: 15%;
         bottom: 15%;
         left: max(20%, calc(50% - 550px));
         right: max(20%, calc(50% - 550px));
         background: $secondary-color-90-transparant;
         border-radius: 50px;
      }

      .container {
         
         display: flex;
         align-items: center;
         height: 100%;

      }

      #hero-tagline {

         flex: 0 0 50%;
         padding-right: 50px;
         box-sizing: border-box;
         z-index: 1;
         animation-delay: 0.2s;
         animation: fade-in-translate 2s forwards;

         @media screen and (max-width: 768px) {
            flex-basis: 55%;
            padding-right: 30px;;
         }

         h1 {
         
            font-size: 3.4rem;
            line-height: 4.2rem;
   
            span:not(.stress) {
               color: $primary-color;
            }
   
         }

         p {
            margin-bottom: 25px;
            line-height: 1.5em;
            font-size: 1.5rem;
         }

         .buttons {

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;

            .btn-container {
            
               flex: 0 0 calc(50% - 3px);
               min-width: auto;

               @media screen and (max-width: 768px) {
                  flex: 0 0 100%;
                  margin-bottom: 8px;
               }

            }

         }

      }

      .container {
         position: relative;
      }

      #hero-images {

         position: relative;
         flex: 0 0 50%;
         margin-top: -180px;
         margin-bottom: -100px;
         align-items: flex-start;
         z-index: 1;

         @media screen and (max-width: 768px) {
            flex-basis: 60%;
         }

         #hero-image-wrapper {

            display: flex;
            align-items: center;

            .col {
            
               flex: 0 0 50%;
               padding: 0 5px;
               box-sizing: border-box;
               z-index: 1;
   
               img {
                  
                  width: 100%;
                  border-radius: 30px;
                  margin-bottom: 6px;
                  object-fit: cover;
                  opacity: 0;
                  box-sizing: border-box;
                  animation: fade-in-translate .8s forwards;
                  animation-delay: 0.4s;
                  box-shadow: 0px 3px 10px rgb(0, 0, 0, 0.15);
   
               }
   
               &:first-child {
                  
                  img:first-child {
                     margin-top: -10px;
                     height: 200px;
                     margin-left: 20px;
                     width: calc(100% - 20px);
                     animation-delay: 0.6s;
                  }
   
                  img:nth-child(2) {
                     height: 230px;
                     animation-delay: 1.2s;
                  }
   
                  img:last-child {
                     height: 220px;
                     margin-bottom: -90px;
                     margin-left: 40px;
                     width: calc(100% - 40px);
                     animation-delay: 1s;
                  }
   
               }
   
               &:last-child {
                  
                  padding-top: 100px;

                  img:first-child {
                     height: 150px;
                  }
   
                  img:last-child {
                     width: 80%;
                     height: 290px;
                     animation-delay: 0.8s;
                  }
   
               }
   
               @keyframes fade-in-translate {
                  0% {
                     opacity: 0;
                     @include transform(translateY(-40px));
                  }
                  100% {
                     opacity: 1;
                     @include transform(translateY(0));
                  }
               }
   
               @keyframes fade-in-no-translate {
                  0% {
                     opacity: 0;
                  }
                  100% {
                     opacity: 1;
                  }
               }
   
            }

            @media screen and (max-width: 572px) {
            
               .col {
                  flex-basis: 100%;
               }
   
               .col:last-child {
                  flex-basis: 0;
               }
   
            }

         }

      }

   }

   #usps {

      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin-top: 80px;
      margin-bottom: 160px;

      h2 {
         font-size: 1.7rem;
         flex: 0 0 100%;
         margin-bottom: 12px;
      }

      & > p {
         font-size: 1.2rem;
         flex: 0 0 100%;
         margin-bottom: 16px;
      }

      &:after {
         content: '';
         position: absolute;
         top: 50%;
         left: 10%;
         right: 10%;
         bottom: -25px;
         background: $secondary-color-90-transparant;
         border-radius: 50px;
         z-index: -1;
      }

      .usp {

         flex: 0 0 calc(33.3% - 12px);
         margin-right: 12px;
         padding: 25px 30px;
         box-sizing: border-box;
         text-align: center;
         background: rgb(255, 255, 255);
         border:$light-border;
         border-radius: 30px;
         opacity: 0;
         @include transform(translateY(-10px));

         @media screen and (max-width: 572px) {
            flex: 0 0 100%;
            margin-bottom: 10px;
         }

         svg {
            margin-bottom: 3px;
            fill: $primary-color;
         }

         h3 {
            margin-bottom: 5px;
         }

         p {
            font-size: 1.1rem;
         }

         &:last-child {
            
            margin-right: 0;

         }

      }

      &.in-view {

         .usp {

            animation: fade-in-element .5s forwards;
            animation-delay: .3s;

            &:last-child {
               animation-delay: 1.3s;
            }

            &:nth-of-type(2) {
               animation-delay: .8s;
            }

         }

      }

   }

   #info {

      padding: 100px 0;

      & > h2 {
         margin-bottom: 10px;
      }

      & > p {
         font-size: 1.3rem;
         margin-bottom: 35px;
      }

      .row {
       
         position: relative;
         display: flex;
         align-items: center;
         padding: 40px 15px;
         margin-bottom: 40px;
         border-radius: 30px;
         background: rgb(240, 240, 240);
         opacity: 0;

         &.in-view {
            animation: fade-in-element .5s forwards;
            animation-delay: .5s;
         }

         &:nth-of-type(2) {
            background: none;
         }

         @media screen and (max-width: 768px) {
            align-items: stretch;
         }

         @media screen and (min-width: 768px) {

            &:nth-of-type(2) {
               flex-direction: row-reverse;
            }

         }

         & > div {

            flex: 0 0 50%;
            padding: 0 35px;
            box-sizing: border-box;

            h2 {
               margin-bottom: 10px;
            }

            p {
               font-size: 1.2rem;
               line-height: 1.4em;
            }

            & > span {
               display: inline-block;
               margin-bottom: 16px;
               padding: 7px 10px;
               border-radius: 10px;
               font-size: .7rem;
               text-transform: uppercase;
               font-weight: 900;
               color: rgb(255, 255, 255);
               background: $secondary-color-50-transparant;
            }

            &.image {

               @media screen and (max-width: 768px) {
                  flex: 0 0 70vw;
               }

               img {
               
                  width: 100%;
                  max-width: 60vw;
                  height: 280px;
                  object-fit: cover;
                  border-radius: 30px;
   
                  @media screen and (max-width: 768px) {
                     height: 100%;
                     object-position: 80% 50%;
                  }
   
               }   

            }

         }

         &:first-of-type {

            img {
               object-position: 50% 20%;
            }
   
         }

         &:last-of-type {
            margin-bottom: 0;
         }

      }

   }

   #cta-quickscan {

      position: relative;
      margin: 140px 20px 260px;
      background: $secondary-color-90-transparant;
      border-radius: 50px;
      opacity: 0;

      &.in-view {
         animation: fade-in-element .5s forwards;
         animation-delay: .5s;
      }


      #cta-quickscan-bg {

         position: absolute;
         top: -25px;
         left: 0;
         right: 0;
         bottom: 0;
         overflow: hidden;
         border-radius: 50px;

         @media screen and (max-width: 1400px) {
            display: none;
         }

         .arrow-row {

            white-space: nowrap;
            width: calc(100% + 100px);
            overflow: hidden;
            margin-bottom: 50px;
   
            svg {
             
               display: inline-block;
               margin-right: 50px;
               height: 20px;
               width: 20px;
               fill: $secondary-color-50-transparant;
               opacity: .4;
               @include transform(rotate(-45deg));

               &:nth-child(2n) {
                  @include transform(rotate(45deg));
               }

               &:nth-child(3n) {
                  @include transform(rotate(135deg));
               }

               &:nth-child(4n) {
                  @include transform(rotate(-135deg));
               }

            }
   
            &:nth-child(2n) {
               @include transform(translateX(-40px));
            }
   
         }

      }

      @media screen and (max-width: 768px) {
         margin-top: 250px;
      }

      .container {
         
         background: linear-gradient(90deg,
            transparentize($secondary-color-90-transparant, 1),
            $secondary-color-90-transparant 30px,
            $secondary-color-90-transparant calc(100% - 30px),
            transparentize($secondary-color-90-transparant, 1)
         );

         @media screen and (max-width: 1400px) {
            background: transparent;
         }

      }

      & > div {

         position: relative;
         padding: 50px 60px;
         box-sizing: border-box;

         h2 {
            margin-bottom: 16px;
         }

         p {
            box-sizing: border-box;
            font-size: 1.2rem;
            margin-bottom: 32px;
         }

         h2, p {

            padding-right: 380px;

            @media screen and (max-width: 768px) {
               padding-right: 0;
            }

         }

         img {

            position: absolute;
            height: calc(100% + 60px);
            width: 300px;
            top: 50%;
            right: 15px;
            object-fit: cover;
            object-position: 50% 20%;
            border-radius: 30px;
            border: 8px solid rgb(255, 255, 255);
            @include transform(translateY(-50%));

            @media screen and (max-width: 768px) {
               left: 50%;
               bottom: calc(100% - 25px);
               z-index: -1;
               top: auto;
               height: 200px;
               width: 80vw;
               @include transform(translateX(-50%));
            }

         }

      }

   }

   #roadmap-scroll-area {

      position: relative;
      box-sizing: border-box;
      background: linear-gradient(rgb(255, 255, 255), $secondary-color-80-transparant);

      #roadmap-viewport {
         
         position: absolute;
         top: 0;
         left: 0;
         right: 0;
         height: 100vh;
         overflow: hidden;

         #roadmap-bg {

            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%) rotate(-30deg));

            .roadmap-bg-row {

               display: flex;
               justify-content: center;
               
               &:nth-child(2n) {
                  @include transform(translateX(-50%));
               }

               h3 {
                  flex: 1 0 auto;
                  margin-right: 2rem;
                  font-family: $header-font;
                  font-size: 10rem;
                  line-height: 12rem;
                  color: $secondary-color-90-transparant;
               }

            }

         }

         & > h2, & > p {
            display: block;
            position: absolute;
            width: 100vw;
            top: 100px;
            text-align: center;
         }

         & > p {
            font-size: 1.2rem;
            top: calc(1.5rem + 110px);
         }

         #roadmap-container {

            position: absolute;
            display: flex;
            align-items: center;
            top: 50%;
            left: 50%;
            width: 800px;
            max-width: 90vw;
            padding: 50px 30px;
            box-sizing: border-box;
            background: rgb(255, 255, 255);
            border-radius: 50px;
            @include transform(translate(-50%, -50%));

            #roadmap-progress {

               display: flex;
               align-items: center;
               justify-content: flex-end;
               flex: 0 0 50%;
               box-sizing: border-box;
               z-index: 1;

               @media screen and (max-width: 768px) {
                  
                  flex: 0 0 0;

               }

               #roadmap-graphic {

                  height: 200px;
                  margin-right: 5px;

                  svg {
                     width: 200px;
                     height: 200px;

                  }

                  @media screen and (max-width: 768px) {
                     
                     display: none;

                  }

               }

               #roadmap-progress-dots {

                  position: relative;
                  overflow: hidden;
                  padding: 0 35px;

                  #roadmap-progress-dots-bg {

                     position: absolute;
                     top: 0;
                     left: 50%;
                     background: linear-gradient($secondary-color-90-transparant, $secondary-color-50-transparant, $secondary-color-90-transparant);
                     height: 100%;
                     width: 4px;
                     border-radius: 4px;
                     @include transform(translateX(-50%));
   
                  }
   
                  .roadmap-progress-dot {
   
                     height: 33px;
                     width: 33px;
                     border-radius: 50%;
                     background: $secondary-color-80-transparant;
                     margin-bottom: 5px;
                     transform-origin: 50% 50%;
   
                     &:last-child {
                        margin-bottom: 0;
                     }
   
                  }

                  #roadmap-progress-indicator {

                     position: absolute;
                     top: -10%;
                     left: 50%;
                     height: 150px;
                     width: 150px;
                     background: radial-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, 0) 80%);
                     @include transform(translate(-50%, -50%));

                  }

               }

            }

            #roadmap-text {
               
               flex: 0 0 50%;
               max-width: 75vw;

               @media screen and (max-width: 768px) {
                  
                  flex: 0 1 100%;

               }
               
               span {
                  display: block;
                  margin-bottom: 5px;
                  text-transform: uppercase;
                  font-weight: 900;
                  font-size: .8rem;
                  color: rgb(180, 180, 180);
               }

               h2 {
                  margin-bottom: 5px;
                  line-height: 2.2rem;
               }

               p {
                  color: $secondary-color;
                  font-size: 1.3rem;
               }

            }

         }

      }

   }

   #contact {

      position: relative;
      height: calc(100 * var(--vh, 1vh));
      background: $secondary-color-95-transparant;

      &:after {
         content: '';
         position: absolute;
         top: 50%;
         left: 50%;
         border-radius: 50px;
         height: 85%;
         width: 50%;
         background: $secondary-color-80-transparant;
         @include transform(translate(-50%, -50%));
         z-index: 0;
      }

      .container {
         
         position: relative;
         max-width: 1200px;
         height: 100%;
         z-index: 1;

         .form-container {
            
            position: absolute;
            top: 50%;
            left: 0;
            padding: 50px;
            width: 70%;
            max-width: 900px;
            border-radius: 30px;
            box-sizing: border-box;
            border: none;
            @include transform(translateY(-50%));

            @media screen and (max-width: 768px) {
               padding: 35px;
            }

            @media screen and (max-width: 572px) {
               width: 100%;
            }

            p {
               margin-bottom: 10px;
            }

            ul {
   
               margin-bottom: 15px;

               li {

                  display: flex;
                  align-items: center;
                  padding: 3px 0;
                  
                  svg {
                     height: 1.1rem;
                     width: 1.1rem;
                     margin-right: 10px;
                     fill: $secondary-color-50-transparant;
                  }

               }

            }

            .btn-container {
               margin-right: 0;
               max-width: 400px;
            }

            #contact-form-success {

               padding: 20px;
               margin-bottom: 10px;
               box-sizing: border-box;
               border-radius: 20px;
               background: $secondary-color-90-transparant;
               color: $secondary-color;

            }

         }

         .img-container {

            position: absolute;
            top: 50%;
            right: 0;
            width: 35%;
            @include transform(translateY(-50%));

            @media screen and (max-width: 572px) {
               opacity: 0;
            }

            img {
               position: relative;
               height: min(700px, 80vh);
               width: 100%;
               border-radius: 50px;
               min-height: 400px;
               object-fit: cover;
               object-position: 50% 65%;
               opacity: 1;
            }

            &:after {
               content: '';
               position: absolute;
               top: 0;
               left: 0;
               bottom: 0;
               right: 0;
               background: transparentize($secondary-color, .85);
               border-radius: 50px;
            }

         }         

      }
   }

}