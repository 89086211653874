@import '../variables.scss';

#chat {

   display: flex;
   height: 80vh;
   width: 100%;
   border-radius: 20px;
   border: $light-border;
   border-width: 1px;;
   overflow: hidden;

   #chat-chats {

      flex: 0 0 300px;
      height: 100%;
      background: rgb(255, 255, 255);
      border-right: 1px solid rgb(235, 235, 235);
      box-sizing: border-box;
      overflow-y: auto;

      @media screen and (max-width: 972px) {
         flex-basis: 270px;
      }

      @media screen and (max-width: 768px) {
         
         flex-basis: 100%;

         &.hide-chats {
            flex-basis: 0;
         }

      }

      h4 {
       
         display: flex;
         align-items: center;
         justify-content: space-between;
         width: 100%;
         box-sizing: border-box;
         padding: 14px 20px;
         font-size: .7rem;
         text-transform: uppercase;
         border-bottom: 1px solid $secondary-color-90-transparant;
         user-select: none;

         span {
            
            font-size: .7rem;

            span {
               display: inline-block;
               font-weight: 600;
               margin-left: 5px;
               padding: 3px 5px;
               border-radius: 3px;
               background: $secondary-color-90-transparant;
            }

         }

         svg {
            
            display: block;
            height: .7rem;
            width: .7rem;
            padding: 10px;
            margin: -8px;
            fill: $secondary-color;
            cursor: pointer;
            pointer-events: all;
            @include transition(transform 0.2s);
            @include transform(translateX(6px) rotate(90deg));

            path {
               pointer-events: none
            }

         }

         &.hide {
            
            svg {
               @include transform(translateX(6px) rotate(0));
            }

         }
         
         & + .no-bookings-status-message {
            padding: 14px 20px;
            font-size: .8rem;
            border-bottom: 1px solid $secondary-color-90-transparant;
            color: rgb(150, 150, 150);
         }

      }

      h4.hide + div, h4.hide + .no-bookings-status-message {
         display: none;
      }

      .chat-thumbnail {
         
         display: block;
         position: relative;
         padding: 16px 20px;
         box-sizing: border-box;
         border-bottom: 1px solid rgb(235, 235, 235);
         background: rgb(255, 255, 255);
         cursor: pointer;
         @include transition(background-color 0.2s);

         &:hover {
            background: rgb(251, 251, 251);
         }

         &.selected, &.selected:hover {
            background: rgb(248, 248, 248);
            cursor: default;
         }

         & > span {
            
            display: block;
            font-size: .9rem;

            &.chat-thumbnail-location {
               font-weight: 900;
            }

            &.chat-thumbnail-new {
               position: absolute;
               top: 16px;
               right: 20px;
               font-size: .8rem;
               font-weight: 600;
               padding: 6px 8px;
               border-radius: 8px;
               color: $secondary-color;
               background: $secondary-color-90-transparant;
            }

            &.chat-thumbnail-date {
               
               margin-top: 3px;
               font-size: .75rem;
               color: $secondary-color;

               span {
                  display: inline-block;
                  margin: 0 3px;
                  font-size: .8em;
                  @include transform(translateY(-.1em));
               }

            }

            &.chat-thumbnail-activity {
               font-size: .8rem;
               margin-top: 6px;
               padding: 5px 10px;
               box-sizing: border-box;
               border-radius: 8px;
               background: $secondary-color-90-transparant;
               color: $secondary-color;
            }

         }

      }

   }

   #chat-messages {

      position: relative;
      width: 100%;
      height: 100%;

      #chat-placeholder {

         position: absolute;
         top: 50%;
         left: 50%;
         max-width: 200px;
         text-align: center;
         @include transform(translate(-50%, -50%));

         @media screen and (max-width: 768px) {
            display: none;
         }

         svg {
            height: 120px;
            width: 120px;
            opacity: .5;
         }

         p {
            font-size: .9rem;
            opacity: .7;
         }

      }

      #chat-messages-header {

         position: relative;
         display: flex;
         align-items: center;
         justify-content: space-between;
         width: 100%;
         padding: 12px 15px;
         height: 50px;
         box-sizing: border-box;
         background: rgb(255, 255, 255);
         border-bottom: 1px solid rgb(235, 235, 235);
         z-index: 99;
         
         #chat-messages-header-names {

            display: flex;
            align-items: center;

            svg {
               display: none;
               width: .9rem;
               height: .9rem;
               padding: 5px;
               margin: -5px;
               margin-right: 8px;
               fill: rgb(150, 150, 150);
               @include transform(translateY(1px) rotate(180deg));

               @media screen and (max-width: 768px) {
                  display: inline;
                  cursor: pointer;
               }

            }
            
            div {

               span {
                  display: inline-block;
                  margin-right: 4px;
                  font-size: .9rem;
                  font-weight: 900;
                  color: $secondary-color;
                  @include transform(translateY(-1px));
               }

            }

         }

         #chat-messages-header-icons {

            display: flex;

            input[type=checkbox] {
               position: absolute;
               height: .1px;
               width: .1px;
               opacity: 0;
            }

            label {

               position: relative;
               display: inline-block;
               height: 24px;
               margin-left: 8px;

               &:after {
                  content: '';
                  position: absolute;
                  top: -2px;
                  left: -5px;
                  right: -4px;
                  bottom: -6px;
                  border-radius: 5px;
                  background: $secondary-color-80-transparant;
                  z-index: -1;
                  opacity: 0;
                  @include transform(scale(0));
                  @include transition(transform 0.2s, opacity 0.2s);
               }

               svg {

                  box-sizing: border-box;
                  fill: $secondary-color;
                  cursor: pointer;
                  @include transform(translateY(2px));

               }
            
            }

            .chat-messages-header-panel-content {

               position: absolute;
               padding: 25px 25px;
               top: calc(100% + 10px);
               right: 10px;
               background: rgb(255, 255, 255);
               box-sizing: border-box;
               border-radius: 10px;
               border:$light-border;;
               opacity: 0;
               pointer-events: none;
               @include transform(translateY(-10px));
               @include transition(opacity .2s, transform .2s);

               &#chat-messages-header-panel-qr {

                  p {
                     display: block;
                     font-weight: 900;
                     font-size: .7rem;
                     width: 100%;
                     max-width: 200px;
                     box-sizing: border-box;
                     padding: 10px 20px;
                     margin: 3px auto -3px;
                     text-align: center;
                     background: rgba(200, 0, 0, 0.1);
                     border-radius: 8px;
                     color: $error-color;
                  }

                  svg {
                     width: 200px;
                     border-radius: 8px;
                  }

               }

               &#chat-messages-header-panel-info {

                  input[type=submit] {
                     display: block;
                     width: auto;
                     min-width: 0;
                     margin: 12px auto 0;
                     padding: 0;
                     font-weight: 900;
                     font-size: .7rem;
                     background: transparent;
                     border: none;
                     color: rgb(100, 100, 100);
                  }

               }

               ul {

                  width: 200px;
                  text-align: left;

                  li {

                     padding: 15px;
                     box-sizing: border-box;
                     margin-bottom: 8px;
                     border-radius: 8px;
                     background: $secondary-color-90-transparant;

                     h4 {
                        margin-bottom: 3px;
                        line-height: .8rem;
                        text-transform: uppercase;
                        font-size: .8rem;
                     }
   
                     span {
                        line-height: .9rem;
                        font-size: .9rem;
                     }

                     svg {
                        height: .9rem;
                        width: .9rem;
                        margin-right: 5px;
                        fill: $secondary-color;
                        @include transform(translateY(2px));
                     }

                     &:last-child {
                        margin-bottom: 0;
                     }

                  }

               }

            }

            input[type=checkbox]:checked {

               & ~ .chat-messages-header-panel-content {
                  opacity: 1;
                  pointer-events: all;
                  @include transform(translateY(0));
               }

               & ~ label:after {
                  opacity: 1;
                  @include transform(scale(1));
               }

            }

         }

      }

      #chat-messages-wrapper {

         display: flex;
         flex-direction: column-reverse;
         height: calc(100% - 120px);
         overflow-y: auto;
         background: $secondary-color-95-transparant;

         #chat-messages-content {

            padding: 5px 15px;
            box-sizing: border-box;

            .chat-message {

               margin-top: 10px;

               p {
                  
                  margin: 0 auto 0 0;
                  word-break: break-word;
                  max-width: 90vw;
                  width: 500px;
                  font-size: .9rem;
                  line-height: 1.4rem;
                  border-radius: 10px;
                  padding: 15px;
                  background: rgb(255, 255, 255);
                  border: $light-border;

                  a {
                     color: adjust-color($secondary-color-50-transparant, $red: -25, $blue: -25, $green: -25);
                  }

                  @media screen and (max-width: 972px) {
                     max-width: 80%;
                     width: 350px;
                  }

               }

               span {
                  display: block;
                  width: 100%;
                  margin-top: 3px;
                  font-size: .65rem;
                  line-height: 1.2rem;
                  font-weight: 700;
                  color: rgb(30, 30, 30);
               }

               &.you {

                  p {
                     margin: 0 0 0 auto;
                     background: $secondary-color-80-transparant;
                     border: 2px solid rgb(255, 255, 255);
                  }

                  span {
                     text-align: right;
                  }

               }

            }

            &.cancelled {

               .chat-message:last-child {

                  p {
                     color: rgb(200, 50, 50);
                     border-color: rgb(200, 100, 100);
                     background: rgb(250, 230, 230);
                  }

               }

            }

         }

      }

      #chat-messages-footer {

         width: 100%;

         .form-container {

            width: 100%;
            box-sizing: border-box;
            padding: 5px;
            height: 70px;
            overflow: hidden;
            background: $secondary-color-95-transparant;
            border-radius: 0;
            border: none;
            border-top: 1px solid rgb(215, 215, 215);

            form {

               display: flex;
               justify-content: space-between;
               align-items: center;
               height: 60px;

               .input-container {

                  flex: 0 0 calc(100% - 60px);
                  margin-bottom: 0;
   
                  textarea {
                     
                     box-sizing: border-box;
                     height: 60px;
                     padding-top: 18px;
                     width: 100%;
   
                     &::placeholder {
                        color: rgb(100, 100, 100);
                     }
   
                     &:focus, &.not-empty {
                        border: $light-border;
                     }
   
                     & + .placeholder {
                        display: none;
                     }
      
                  }
   
                  .max-length {
                     right: 10px;
                  }
   
               }
   
               .oneline-submit-container {
                  height: 60px;
               }
   
               p {
                  flex: 0 0 100%;
                  padding-left: 10px;
                  padding-right: 80px;
                  box-sizing: border-box;
                  font-size: .9rem;
                  font-weight: 700;
               }
   
               .form-buttons {
                  margin-bottom: 0;
               }

            }

         }

      }

   }

}