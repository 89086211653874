@import '../variables.scss';

#finance {

   & > div + h3 {
      margin-top: 20px;
   }

   #finance-settlements {

      padding: 20px;
      background: rgb(255, 255, 255);
      border-radius: 15px;
      border: $light-border;

      #no-settlements {
         color: $secondary-color-50-transparant;
      }

      .settlement {
         
         display: flex;
         justify-content: space-between;
         width: 100%;
         padding: 10px 15px;
         border-radius: 15px;
         box-sizing: border-box;
         margin-bottom: 10px;
         background: $secondary-color-90-transparant;

         &:last-child {
            margin-bottom: 0;
         }

         .settlement-date {
            font-size: .9rem;
            font-weight: 600;
            color: $secondary-color;
         }

         .settlement-amount {
            font-size: .9rem;
            font-weight: 600;
            color: $secondary-color-50-transparant;
         }

      }

   }

}