@import '../../variables.scss';

#cookie-banner {

   position: fixed;
   bottom: 15px;
   right: 15px;
   max-width: calc(100vw - 30px);
   max-height: 75vh;
   overflow-y: auto;
   width: 500px;
   padding: 20px;
   box-sizing: border-box;
   z-index: 99999;
   background: rgb(255, 255, 255);
   border-radius: 7px;
   box-shadow: 0 4px 10px rgba(0, 0, 0, .14);

   h2 {
      
      margin-bottom: 3px;
      font-size: 1.2rem;

   }

   h3 {
      margin-bottom: 2px;
      font-size: 1rem;
   }

   #cookie-banner-consents {
      
      margin: 18px 0;

      .cookie-consent {
         display: flex;
         align-items: center;
         padding: 12px 0;
         border-bottom: 1px solid #ebebeb;

         .text {
            flex: 0 0 calc(100% - 70px);
         }

         .switch {
            
            flex: 0 0 70px;

            .checkbox {
               margin-left: 22px;
            }

         }

         &:last-child {
            border-bottom: none;
         }

      }

   }

   #cookie-banner-buttons {

      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
      
      & > * {
         
         flex: 0 0 calc(50% - 3px);

         @media screen and (max-width: 500px) {
            
            flex: 0 0 100%;

            &:first-child {
               margin-bottom: 5px;
            }

         }

      }

      .btn {
         min-width: auto;
         max-width: 100%;
      }

      #cookie-banner-expand {
         
         padding: 5px 0;
         cursor: pointer;

         svg {
            width: 10px;
            height: 10px;
            margin-left: 4px;
            @include transform(rotate(90deg));
         }

      }

   }

   & > svg {
      display: block;
      margin: 0 auto;
      margin-bottom: 15px;
      width: 130px;
      height: 25px;
   }

}