@import '../../variables.scss';

.btn-container {

   display: inline-block;
   position: relative;
   min-width: 200px;

   .btn, input[type=submit] {
      
      display: inline-block;
      position: relative;
      width: 100%;
      font-family: $button-font;
      font-size: .7rem;
      font-weight: $button-font-weight;
      text-transform: uppercase;
      padding: 18px 25px;
      box-sizing: border-box;
      outline: none;
      border: $light-border;
      border-radius: 15px;
      text-align: left;
      text-decoration: none;
      color: $secondary-color;
      cursor: pointer;
      @include transition(background-color 0.2s, border-color 0.2s);

      @media screen and (max-width: 572px) {
         font-size: .75rem;
      }

      &:hover {
         background: $secondary-color-95-transparant;
      }

      &.cta {
         
         border: 2px solid $cta-color;
         background: $cta-color;
         color: rgb(255, 255, 255);

         &:hover {
            background: adjust-color($cta-color, $red: -15, $blue: -15, $green: -15);
            border-color: adjust-color($cta-color, $red: -15, $blue: -15, $green: -15);;
         }

         & + svg path {
            fill: rgb(255, 255, 255);
         }

      }

      & + svg {

         position: absolute;
         top: 50%;
         right: 20px;
         height: .5rem;
         width: .5rem;
         pointer-events: none;
         @include transform(translate(0px, -50%));

         path {
            fill: $secondary-color;
         }

      }
      
      &.disabled, &.disabled:hover {
         background: rgb(150, 150, 150);
         border-color: rgb(150, 150, 150);
         pointer-events: none;
      }

   }

}