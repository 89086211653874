@import '../variables.scss';

#profile {

    h3 {
     
        flex: 0 0 100%;
        margin-top: 0;
        margin-bottom: 5px;

        & + p {
            
            flex: 0 0 100%;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }

        }

    }

    section {
        
        padding-bottom: 35px;
        margin-bottom: 25px;
        border-bottom: $light-border;

        &.first {
            padding-bottom: 0;
            margin-bottom: 20px;
            border-bottom: none;
        }

        &.last {
            border-bottom: 0;
        }

    }

    #profile-name {

        display: flex;
        align-items: center;
        flex-wrap: wrap;

        #profile-image {

            #profile-image-placeholder {
                
                position: relative;
                width: 100px;
                height: 100px;
                margin-right: 20px;
                background: $secondary-color-80-transparant;
                border-radius: 50%;
                overflow: hidden;

                svg {
                    position: absolute;
                    bottom: 0;
                    left: calc(50% - 40px);
                    width: 80px;
                    height: 80px;
                    fill: $secondary-color-50-transparant;
                    opacity: .5;
                }

            }

        }

        #profile-name-text {

            h3, h3 span {
            
                font-size: 1.8rem;
                margin: 0;
    
                @media screen and (max-width: 572px) {
                    
                    span {
                        display: block;
                        line-height: 2rem;
                    }
                    
                }
    
            }
            
            h3 {
                
                position: relative;
                margin-bottom: 5px;
                cursor: pointer;

                svg {
                    position: absolute;
                    left: calc(100% + 3px);
                    top: 50%;
                    height: 20px;
                    width: 20px;
                    fill: $secondary-color;
                    pointer-events: none;
                    opacity: 0;
                    @include transition(opacity 0.15s);
                    @include transform(translateY(-50%));
                }

                &:hover {
                    svg {
                        opacity: 1;
                    }
                }

            }
    
            #profile-badges {

                span {

                    display: inline-block;
                    padding: 7px 12px;
                    font-size: .7rem;
                    text-transform: uppercase;
                    font-weight: 900;
                    border-radius: 8px;
                    color: $wellbits-color;
                    background: $wellbits-color-80-transparant;

                }

            }
            
        }
        
    }

    
    #profile-switcher {

        flex: 0 0 100%;
        margin: 0 0 20px;
        padding: 5px;
        background: white;
        border-radius: 20px;
        border: $light-border;
        white-space: nowrap;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        a {

            position: relative;
            display: inline-block;
            padding: 13px 16px 13px 42px;
            border-radius: 0;
            font-weight: 900;
            font-size: .9rem;
            color: $secondary-color;
            @include transition(background-color 0.2s);

            &.wellbits {
             
                color: $wellbits-color;

                &.active {
                    background: $wellbits-color-90-transparant;
                }

                &:hover {
                    background: $wellbits-color-95-transparant;
                }

                svg {
                    fill: $wellbits-color;
                }

            }

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 1px;
                height: 25px;
                background: $secondary-color-80-transparant;
                @include transform(translateY(-50%));
            }

            &.active {
             
                background: $secondary-color-90-transparant;
                pointer-events: none;

            }

            &:first-child {
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
            }

            &:first-child:after {
                display: none;
            }

            &:hover {
                background: $secondary-color-95-transparant;
            }

            svg {
                position: absolute;
                top: calc(50% - 7px);
                left: 16px;
                height: 14px;
                width: 14px;
                fill: $secondary-color;
            }

        }

    }

    #profile-wallets {

        display: flex;
        flex-wrap: wrap;

        & > p {
            margin-bottom: 5px;
        }

    }

    .wallet {

        display: block;
        position: relative;
        flex: 0 0 calc(33% - 10px);
        margin: 10px 10px 0 0;
        height: 250px;
        box-sizing: border-box;
        background: $secondary-color-80-transparant;
        border-radius: 30px;
        border: 1px solid $secondary-color-50-transparant;
        overflow: hidden;

        @media screen and (max-width: 972px) {
            flex: 0 0 calc(50% - 10px);
        }

        .wallet-text {
        
            display: flex;
            width: calc(100% - 50px);
            position: absolute;
            bottom: 25px;
            left: 25px;

            h4, .balance {
                
                padding: 10px 15px;
                border-radius: 10px;
                background: $secondary-color-90-transparant;

                .title {
                    display: block;
                    margin-bottom: 2px;
                    font-size: .55rem;
                    text-transform: uppercase;
                    color: rgb(50, 50, 50);
                }

            }

            h4 {
                font-size: 1.3rem;
            }

            .balance {
                
                display: flex;
                flex: 0 0 auto;
                flex-wrap: wrap;
                align-items: center;
                font-weight: 900;
                font-size: 1.1rem;
                color: $secondary-color;

                span {
                    flex: 0 0 100%;
                }

                svg {
                    height: 1rem;
                    width: 1rem; 
                    margin-right: 10px;
                    fill: $secondary-color;
                    @include transform(translateY(-1px));
                }

                @media screen and (max-width: 768px) {
                    
                    width: 80px;

                }

            }

            .balance-progress {
                
                position: relative;
                flex: 1 0 auto;
                margin-left: 6px;
                border-radius: 10px;
                z-index: 1;
                background: $wellbits-color-90-transparant;

                .level-title {
                    
                    position: absolute;
                    bottom: calc(100% + 2px);
                    right: 0;
                    z-index: 2;
                    font-size: .7rem;
                    text-transform: uppercase;
                    font-weight: 900;
                    color: $wellbits-color;

                    &.current {
                        left: 0;
                        right: auto;
                    }

                }

                .progress {
                    
                    position: relative;
                    height: 100%;
                    background: $wellbits-color-50-transparant;
                    border-radius: 10px;

                    & > span {
                        
                        display: block;
                        position: absolute;
                        left: calc(100% + 5px);
                        top: 50%;
                        font-size: .65rem;
                        line-height: .8rem;
                        text-align: center;
                        font-weight: 800;
                        color: $wellbits-color;
                        @include transform(translateY(-50%));

                        span:first-child {
                            
                            position: relative;
                            display: block;
                            padding-bottom: 1px;
                            margin-bottom: 3px;

                            &:after {
                                content: '';
                                position: absolute;
                                bottom: -1px;
                                width: 10px;
                                height: 2px;
                                left: 50%;
                                background: $wellbits-color;
                                @include transform(translateX(-50%));
                            }

                        }

                    }

                    &.align-right {

                        & > span {
                            left: auto;
                            right: 5px;
                        }

                    }

                }

            }

        }

        .wallet-waves {
            
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;
            z-index: 0;

            path {
                fill: $secondary-color-50-transparant;
                opacity: .25;
            }

        }

        .wallet-logo {

            position: absolute;
            top: 20px;
            left: 25px;
            height: 20px;
            width: 110px;

            path {
                fill: $secondary-color-50-transparant;
            }

        }

        .wallet-link {

            position: absolute;
            display: flex;
            align-items: center;
            right: 20px;
            bottom: 25px;
            font-weight: 800;
            font-size: .9rem;
            z-index: 1;
            color: $secondary-color;

            svg {
                display: inline-block;
                height: 1.1rem;
                width: 1.1rem;
                margin-left: 4px;
                fill: $secondary-color;
            }

        }

        .wallet-tag {
            position: absolute;
            top: 15px;
            right: 20px;
            padding: 7px 12px;
            border-radius: 8px;
            font-size: .7rem;
            font-weight: 900;
            text-transform: uppercase;
            background: $secondary-color-50-transparant;
            color: rgb(255, 255, 255);
            z-index: 9;
        }

        &:after {
            content: '';
            position: absolute;
            top: -50px;
            right: 30px;
            bottom: -30px;
            width: 50px;
            background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, .4) 70%, rgba(255, 255, 255, 0));
            @include transform(rotate(10deg));
        }

        @media screen and (max-width: 650px) {
            flex-basis: 100%;
            margin-right: 0;
        }

        &.wellbits {

            background: $wellbits-color-80-transparant;
            border-color: $wellbits-color-50-transparant;

            h4, .balance {
                color: $wellbits-color;
                background: $wellbits-color-90-transparant;
            }

            .balance {

                svg {
                    height: 1.2rem;
                    width: 1.2rem; 
                    fill: $wellbits-color;
                }

            }

            .wallet-logo {

                path {
                    fill: $wellbits-color-50-transparant;
                }

            }

            .wallet-link {

                color: $wellbits-color;

                svg {
                    fill: $wellbits-color;
                }

            }

            .wallet-waves {

                path {
                    fill: $wellbits-color-50-transparant;
                }

            }

            .wallet-tag {
                background-color: $wellbits-color-50-transparant;
            }

        }

        &.short {
            
            height: 150px;

            .wallet-waves {
                bottom: -50px;
            }

            .wallet-text {
                bottom: 15px;
            }

        }

    }

    #profile-ads {
            
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 10px -30px -30px 0;

        @media screen and (max-width: 972px) {
              margin-right: -15px;
        }

        
        .ad, .create-ad {
         
              flex: 0 0 calc(33% - 15px);
              margin: 0 15px 30px 0;

              
              @media screen and (max-width: 972px) {
                    flex: 0 0 calc(50% - 15px);
              }
              @media screen and (max-width: 572px) {
                    flex: 0 0 calc(100% - 15px);
              }

        }

        .create-ad {
            
              position: relative;
              min-height: calc(.8rem + 264px);
              box-sizing: border-box;
              background: rgb(255, 255, 255);
              border: 2px dashed $secondary-color-80-transparant;
              border-radius: 20px;
              color: $secondary-color;
              fill: $secondary-color;
              text-align: center;

              .create-ad-content {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    @include transform(translate(-50%, -50%));
              }

              svg {
                    margin: 0 auto 5px;
                    display: block;
              }
              
        }

    }

    #profile-favorites {

        .ads {

              display: flex;
              flex-wrap: wrap;
              align-items: flex-start;
              margin: 10px -30px 0 0;

              @media screen and (max-width: 972px) {
                    margin-right: -15px;
              }


              .ad, .create-ad {

                    flex: 0 0 calc(33% - 15px);
                    margin: 0 15px 30px 0;


                    @media screen and (max-width: 972px) {
                          flex: 0 0 calc(50% - 15px);
                    }
                    @media screen and (max-width: 572px) {
                          flex: 0 0 calc(100% - 15px);
                    }

              }

        }

    }

    #profile-edit-buttons {

        display: flex;
        flex-wrap: wrap;
        margin-right: -6px;

        h3, p {
            flex: 0 0 100%;
        }
        
        .btn-container {
            
            display: inline-block;
            flex: 0 0 calc(33.33% - 6px);
            margin-right: 6px;
            margin-bottom: 8px;

            @media screen and (max-width: 972px) {
                flex: 0 0 calc(50% - 6px);
            }

            @media screen and (max-width: 572px) {
                flex: 0 0 100%;
            }

            svg {
                height: 12px;
                width: 12px;
            }

        }

    }

    #profile-achievements-balance {

        .wallet {
            margin-right: 0;
        }

    }

    #profile-referral {

        #referral-link {
            
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 10px;
            background: rgb(255, 255, 255);
            border: $light-border;
            border-width: 1px;;
            border-radius: 10px;

            h4, h4 span {
                font-size: 1rem;
            }

            h4 {
                
                position: relative;
                display: inline-block;
                padding: 15px 42px 15px 20px;
                box-sizing: border-box;
                border-radius: 10px;
                background: rgb(240, 240, 240);
                letter-spacing: 1px;
                cursor: pointer;

                @media screen and (max-width: 768px) {
                    flex: 0 0 100%;
                    margin-bottom: 4px;
                }

                span {
                    color: rgb(150, 150, 150);
                }

                svg {
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    height: 16px;
                    width: 16px;
                    fill: rgb(150, 150, 150);;
                    @include transform(translateY(-50%));
                }

            }

            #referral-services {                

                display: flex;

                @media screen and (max-width: 768px) {
                    flex: 0 0 100%;
                    justify-content: space-between;
                }

                a {
                    display: flex;
                    flex-wrap: wrap;
                    margin-left: 4px;
                    align-items: center;
                    justify-content: center;
                    padding: 15px;
                    box-sizing: border-box;
                    border-radius: 6px;
                    background: rgb(240, 240, 240);

                    @media screen and (max-width: 768px) {
                        flex: 0 0 calc(20% - 4px);
                        margin-left: 0;
                    }

                    svg {
                        height: 20px;
                        width: 20px;
                        fill: $secondary-color;
                    }

                }

            }

        }

    }

    #profile-achievements {

        .achievements {

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .achievement {

                position: relative;
                flex: 0 0 calc(50% - 5px);
                box-sizing: border-box;
                padding: 25px 130px 25px 22px;
                margin-bottom: 10px;
                border-radius: 20px;
                background: $wellbits-color-80-transparant;
                border: 1px solid $wellbits-color-50-transparant;
                overflow: hidden;

                @media screen and (max-width: 768px) {
                    flex: 0 0 100%;
                }

                @media screen and (max-width: 572px) {
                    padding-left: 10px;
                }

                h4 {
                    margin-bottom: 5px;
                }

                p {
                    font-size: .9rem;
                    font-weight: 600;
                }

                h4, p {
                    
                    position: relative;
                    color: $wellbits-color;

                    span {    
                        display: inline-block;
                        padding: 5px 12px;
                        box-sizing: border-box;
                        border-radius: 8px;
                        background: $wellbits-color-90-transparant;
                    }
                }


                h4, p, & > span {
                    z-index: 2;
                }
                
                & > span {
                    
                    position: absolute;
                    display: block;
                    color: $wellbits-color;
                    font-weight: 900;
                    font-size: 1.2rem;
                    right: 20px;
                    top: 50%;
                    @include transform(translateY(-50%));

                    svg {
                        margin-left: 6px;
                        fill: $wellbits-color;
                        @include transform(translateY(calc(22px - 1.2rem)));
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        top: calc(50% - 80px);
                        left: calc(50% - 80px);
                        height: 160px;
                        width: 160px;
                        background: radial-gradient(rgba(255, 255, 255, .8), rgba(255, 255, 255, 0) 50%);
                        z-index: -1;
                    }

                }

                .status {
                    
                    display: flex;
                    align-items: center;
                    position: absolute;
                    top: 5px;
                    right: 10px;
                    padding: 5px 8px;
                    border-radius: 8px;
                    font-size: .8rem;
                    font-weight: 900;
                    background: $wellbits-color-50-transparant;
                    color: rgb(255, 255, 255);
                    z-index: 2;
                    @include transform(translateY(-50%));

                    svg {
                        height: .8rem;
                        width: .8rem;
                        margin-right: 5px;
                        fill: rgb(255, 255, 255);
                    }

                }

                &.grey {

                    background-color: rgb(220, 220, 220);
                    border-color: rgb(100, 100, 100);

                    h4, p, span {
                        color: rgb(100, 100, 100);
                    }

                }

                &.reserved {
                    border-style: dashed;
                }

                .achievement-waves-wrapper {

                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    top: 0;
                    overflow: hidden;
                    z-index: 0;

                    .achievement-waves {
            
                        position: absolute;
                        bottom: -20px;
                        left: 0px;
                        width: 100%;
            
                        path {
                            fill: $wellbits-color-50-transparant;
                            opacity: .25;
                        }
            
                    }

                }

            }

        }

    }

    #edit-profile {

        position: fixed;
        top: 120px;
        padding: 30px;
        box-sizing: border-box;
        right: 12px;
        width: 800px;
        max-width: calc(100vw - 24px);
        bottom: 12px;
        background: rgb(255, 255, 255);
        border: $light-border;
        box-shadow: $bottom-shadow-strong;
        border-radius: 30px;
        z-index: 99;
        opacity: 1;
        animation: fade-in 0.3s;
        overflow-y: auto;
        @include transition(transform 0.3s);
  
        @keyframes fade-in {
           0% {
              transform: translateX(100%);
           }
           100% {
              transform: translateX(0);
           }
        }

        &.fading-out {
            transform: translateX(100%);
        }

        .form-container {

            flex: 0 0 calc(50% - 5px);
            margin-bottom: 25px;
            box-sizing: border-box;
            border: none;
            padding: 0;
    
            @media screen and (max-width: 972px) {
                  flex: 0 0 100%;                        
            }
    
            h4 {
              
                  margin: 0 0 3px !important;
    
                  & + p {
                        margin-bottom: 15px;
                  }
    
            }
    
            form {
    
                  .input-container {
                   
                        margin-bottom: 5px;
    
                        & + .error-message {
                              margin-bottom: 5px;
                        }
    
                  }
    
                  .password-requirements {
                        margin-top: -2px;
                  }
    
                  .form-buttons {
                        
                        margin-top: 20px;
                        
                        .btn-container {
                              max-width: 200px;
                              margin-right: 0;
                        }
    
                  }
    
                  .two-inputs {
                        
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
    
                        & > div {
                              
                              flex: 0 0 calc(50% - 3px);
    
                              @media screen and (max-width: 572px) {
                                    flex: 0 0 100%
                              }
    
                        }
    
                  }
    
            }
    
        } 

    }

}