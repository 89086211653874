@import '../variables.scss';

#offerings {

   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   margin-bottom: -10px;

   .offering {

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      flex: 0 0 calc(50% - 4px);
      margin-bottom: 8px;
      height: 14rem;
      padding: 20px 24px;
      box-sizing: border-box;
      border-radius: 20px;
      border: $light-border;
      border-width: 1px;
      @include transition(border-color 0.2s, background-color 0.2s);

      @media screen and (max-width: 768px) {
         flex: 0 0 100%;
      }

      &.edit {
         
         border-style: dashed;
         cursor: default;

         #offering-title {

            font-family: $p-font;
            font-weight: 900;
            font-size: 1rem;
            color: $header-color;
            border: none;
            padding: 0;
            border-radius: 0;

            &:not(.not-empty)::placeholder {
               
               color: transparentize($secondary-color, .7);

            }

            &:focus:not(.not-empty)::placeholder {
               color: transparentize($secondary-color, .4);
            }

            & ~ .placeholder, & ~ .max-length {
               display: none;
            }

            &:focus, &:hover {
               background: rgb(255, 255, 255);
            }

         }

         #offering-option {

            width: 150px;

            .placeholder {
               display: none;
            }

            input {
               padding: 6px 10px;
               border-radius: 4px;
               border: none;
               color: $secondary-color;
               font-size: .75rem;
               background: $secondary-color-90-transparant;
            }

            ul {
               width: 150%;
            }

            svg {
               right: 7px;
               height: 8px;
               fill: $secondary-color
            }

         }

         #offering-description {
            
            padding: 10px;
            margin-top: 4px;
            font-size: .8rem;
            line-height: .9rem;
            border-radius: 5px;

            & ~ .placeholder, & ~ .max-length {
               display: none;
            }

            &::placeholder {
               opacity: 1;
               color: rgb(200, 200, 200);
            }

            &:focus, &:hover {
               
               background: rgb(255, 255, 255);

            }

            &:focus {
               
               &::placeholder {
                  color: rgb(150, 150, 150);
               }

            }

         }

         .offering-price {

            position: relative;

            .input-container {

               margin-bottom: 0;

               input {

                  padding: 0;
                  text-align: right;
                  font-weight: 700;
                  font-size: .9rem;
                  color: $secondary-color;
                  border: none;
                  border-radius: 0;

                  & ~ .placeholder, & ~ .max-length {
                     display: none;
                  }

                  &:focus, &:hover {
                     background: rgb(255, 255, 255);
                  }

               }

               .icon {
                  
                  left: auto;
                  right: 30px;
                  @include transform(translateY(-50%));

                  svg {
                     height: .9rem;
                     width: .9rem;
                  }

               }

            }

            #offering-confirm {

               position: absolute;
               left: 0;
               top: 50%;
               padding-top: 10px;
               font-size: .8rem;
               text-decoration: underline;
               z-index: 99;
               color: $primary-color;
               font-weight: 700;
               cursor: pointer;
               @include transform(translateY(-50%));
   
            }

         }

      }

      &.add {

         cursor: pointer;
         border-style: dashed;
         border-width: 2px;

         h4 {
            
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 1rem;
            font-family: $p-font;
            color: $secondary-color-50-transparant;
            margin: 0;
            @include transform(translate(-50%, -50%));
            text-align: center;

            svg {
               display: inline;
               height: 14px;
               fill: $secondary-color-50-transparant;
            }

         }

      }

      .offering-edit, .offering-remove {

         position: absolute;
         top: 16px;
         right: 16px;
         height: 18px;
         width: 18px;
         fill: $secondary-color;
         opacity: 0;
         z-index: 3;
         cursor: pointer;
         @include transition(opacity 0.2s, transform 0.2s);

      }

      .offering-edit {
         transform: scale(0);
      }

      &.edit {

         .offering-remove {
            fill: $error-color;
            opacity: 1;
         }

      }

      &.editable:hover {

         .offering-edit {
            opacity: 1;
            transform: scale(1);
         }

      }

      .offering-info {

         z-index: 2;

         h4 {
            font-size: 1rem;
            margin: 0 0 5px;
            font-family: $p-font;
         }

         .offering-option {

            display: inline-block;
            font-size: .75rem;
            padding: 5px 10px;
            margin-bottom: 8px;
            border-radius: 5px;
            background: $secondary-color-90-transparant;
            color: $secondary-color;
   
         }

      }

      .offering-price {

         display: block;
         width: 100%;
         padding-top: 12px;
         border-top: $light-border-thin;
         text-align: right;
         font-weight: 900;
         font-size: .9rem;
         color: rgb(100, 100, 100);
         background: transparent;

         #offering-price {
            background: transparent;
         }

         svg {
            fill: $secondary-color-50-transparant;
            height: .85rem;
            @include transform(translateY(2px));
         }

         .offering-price-vat {
            display: block;
            margin-top: 0px;
            font-size: .65rem;
            font-weight: 400;
         }

      }

      .input-container, .select {

         margin-bottom: 5px;

      }

      &.selected {
         border-color: rgb(0, 180, 0);
         background: rgba(0, 180, 0, .03);
      }

      &.too-expensive {
         
         pointer-events: none;
         border-color: transparentize($color: $secondary-color, $amount: 0.4);

         & > * {
            opacity: .6;
         }

         .offering-price {
            opacity: 1;
            color: $error-color;
         }

      }

      .move-offering {

         position: absolute;
         right: -3px;
         bottom: -5px;
         height: 12px;
         width: 12px;
         margin: 0;
         padding: 8px;
         background: rgb(255, 255, 255);
         border: $light-border;
         border-radius: 18px;
         fill: $secondary-color;
         cursor: pointer;

         &.left {
            right: auto;
            left: -3px;
            @include transform(scaleX(-1));
         }

      }


   }

   #offerings-balance {
      
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 0 0 100%;
      font-size: .85rem;
      padding: 10px 10px 10px 20px;
      box-sizing: border-box;
      border-radius: 12px;
      margin-bottom: 10px;
      border: $light-border;

      span {
         
         font-weight: 900;
         font-size: .9;
         color: $secondary-color;

         > svg {
            height: 1em;
            fill: $secondary-color;
            @include transform(translateY(2px));
         }

      }

      .btn-container {
         margin: 0;
         max-width: 250px;
      }

      
      @media screen and (max-width: 572px) {
         
         display: block;
         padding-top: 20px;
         padding-bottom: 20px;
         text-align: center;

         .btn-container {
            margin-top: 15px;
            max-width: 100%;
         }

      }

   }  

   #offerings-guarantees {

      flex: 0 0 100%;
      margin-bottom: 10px;
      color: $secondary-color;
      font-family: $p-font;
      font-size: .85rem;
      padding: 15px 20px;
      box-sizing: border-box;
      border-radius: 20px;
      background: $secondary-color-80-transparant;

      li {
         
         display: flex;
         align-items: center;
         padding: 3px 0;

         svg {
            height: 1em;
            width: 1em;
            margin-right: 12px;
            fill: $secondary-color;
         }

      }

   }

   &.edit {

      .editable {
         cursor: pointer;
      }

   }

   &:not(.edit) {
      
      .offering {
         cursor: pointer;
      }

   }

}