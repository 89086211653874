@import '../../variables.scss';

.checkbox {

   input[type=checkbox], input[type=radio] {
   
      position: absolute;
      left: -2000px;
      top: 0px;
      width: .1px;
      height: .1px;
      opacity: 0;

      &:not(:checked) + label:hover {
         background: rgb(247, 247, 247);
      }

      &:checked + label {
         
         background: $secondary-color-90-transparant;

      }

   }

   label {
         
      position: relative;
      display: inline-block;
      margin: 0 4px 4px 0;
      padding: 8px 12px;
      font-size: .75rem;
      font-family: $p-font;
      font-weight: 400;
      border-radius: 15px;
      border: $light-border;
      color: $secondary-color;
      fill: $secondary-color;
      cursor: pointer;
      user-select: none;
      background: rgb(255, 255, 255);
      @include transition(color 0.2s, background-color 0.2s, border 0.2s);

      @media screen and (max-width: 572px) {
         font-size: .85rem;
      }

      svg, .icon {
         display: inline;
         height: 1em;
         width: 1em;
         margin-right: 4px;
         @include transform(translateY(1px));
         @include transition(fill 0.2s);
      }

   }

   &.disabled {

      label, label:hover {
         background: rgb(230, 230, 230) !important;
         cursor: default;
         pointer-events: none;
      }

   }

   &.no-name {

      label {
         
         padding: 12px 22px;
         border-radius: 20px;

         &:after {
            content: '';
            position: absolute;
            left: 3px;
            top: 2px;
            height: 20px;
            width: 20px;
            background: $secondary-color;
            border-radius: 50%;
            background: #d5d5d5;
            @include transition(left 0.2s, background-color 0.3s);
         }

      }

      input[type=checkbox] {

         &:checked {

            & + label {

               background: rgb(255, 255, 255);

               &:after {

                  background: $secondary-color;
                  left: 21px;

               }

            }

         }

      }

   }

}