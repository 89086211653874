@import '../variables.scss';

footer {

   position: relative;
   z-index: 0;
   background: $secondary-color;

   &.hide {
      display: none;
   }

   .light-blue, .dark-blue {
      position: absolute;
      top: 90px;
      left: -30vw;
      right: -30vw;
      height: 2000px;
      z-index: 0;
   }

   .dark-blue {
      background: $secondary-color;
   }

   .container {
      position: relative;
   }

   .form-container {
      border:$light-border;
   }

   #footer-cols {

      display: flex;
      flex-wrap: wrap;
      padding: 30px 0 80px;
      border-top: 16px solid $primary-color;
      @include transform(translateY(-8px));

      .footer-col {
         
         flex: 0 0 25%;

         @media screen and (max-width: 768px) {
            
            & {
               flex: 0 0 50%;
               margin-bottom: 40px;
               text-align: center;
            }

         }

         @media screen and (max-width: 572px) {
            
            & {
               flex: 0 0 100%;
            }

         }

         h4 {
            margin-bottom: 10px;
            color: rgb(255, 255, 255);
         }

         & > svg {

            height: 25px;
            width: 130px;
            filter: brightness(0) saturate(100%) invert(1);

            & + p {

               font-size: .8rem;
               max-width: 150px;
               margin-bottom: 10px;

               @media screen and (max-width: 768px) {
            
                  & {
                     margin: 2px auto 8px;
                  }
      
               }

            }

         }

         p, a {
            color: rgb(255, 255, 255);
         }

         .socials {

            svg {
               padding: 2px;
               height: 26px;
               width: 26px;
               border-radius: 13px;
               margin-right: 4px;
               box-sizing: border-box;
               fill: $secondary-color;
               background: $secondary-color-80-transparant;
               overflow: hidden;
            }

         }

      }

   }

   #footer-info {

      position: relative;
      display: flex;
      justify-content: space-between;
      max-width: 100vw;
      margin: 0 auto;
      z-index: 3;
      border-top: 1px solid $secondary-color-50-transparant;

      p, a {
         display: inline-block;
         padding: 15px 0;
         font-size: .6rem;
         color: rgb(255, 255, 255, .9);
      }

      a {
         margin-left: 10px;
      }

   }

}