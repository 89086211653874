@import '../variables.scss';

#maptile-wrapper {

   position: relative;
   width: 100%;
   z-index: 1;

   #maptile {

      width: 100%;
      border-radius: 20px;
      border: $light-border;
      overflow: hidden;

      .leaflet-container {

         height: 240px;
         width: 100%;

         .leaflet-control-zoom {
            
            border-radius: 10px;
            border: $light-border;
            overflow: hidden;

            .leaflet-control-zoom-in {
               border-bottom: $light-border;
            }

            a {
               font-size: 1rem;
               color: $secondary-color-50-transparant;
               font-family: $p-font;
            }

         }

         .svg-icon {
            fill: $secondary-color;
         }

         .leaflet-control-attribution {
            
            padding: 2px 10px 2px 6px;
            border-top-left-radius: 6px;
            font-size: .7rem;
            background: $secondary-color-95-transparant;
            border-top: $light-border;
            border-left: $light-border;

            a:first-child, span:nth-child(2) {
               display: none;
            }

         }

         .leaflet-marker-pane {

            .leaflet-marker-icon {

               position: relative;

               svg {
                  
                  position: absolute;
                  height: 24px;
                  width: 24px;
                  top: 0;
                  left: 0;

                  &:first-child {
                     width: 30px;
                     height: 30px;
                     margin: -2px 0 0 -3px;
                     fill: rgb(255, 255, 255);
                  }

               }

               &:after {
                  content: '';
                  position: absolute;
                  top: 4px;
                  left: 50%;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background: $secondary-color-50-transparant;
                  @include transform(translateX(-50%));
               }

            }

         }

         .leaflet-popup-pane {

            .leaflet-popup {

               .leaflet-popup-content-wrapper {

                  padding: 0 15px;
                  box-sizing: border-box;
                  box-shadow: none;
                  border-radius: 8px;
                  @include transform(translateY(-16px));

                  .leaflet-popup-content {
                     
                     display: flex;
                     align-items: center;
                     justify-content: space-between;
                     margin: 0;
                     font-family: $p-font;

                     .popup-left {
                           
                        margin-right: 10px;

                        span {

                           font-size: .8rem;
                           margin-bottom: .1rem;
                           display: block;

                           &:first-child {
                              font-weight: 900;
                           }

                        }

                        a {
                           
                           display: block;
                           margin-top: 8px;
                           font-weight: 600;
                           color: $secondary-color;

                           svg {
                              height: .7em;
                              width: .7em;
                              margin-left: 4px;
                              fill: $secondary-color;
                           }

                        }

                     }

                     .popup-right {

                        position: relative;
                        flex-basis: 2rem;
                        height: 100%;
                        margin: 0 -15px 0 15px;

                        img {
                           width: 4rem;
                           height: 6rem;
                           margin-bottom: -5px;
                           border-radius: 0 8px 8px 0;
                           object-fit: cover;
                        }

                        &:after {
                           content: '';
                           position: absolute;
                           top: 0;
                           left: 0;
                           right: 0;
                           height: 100%;
                           border-radius: 0 8px 8px 0;
                           opacity: 1;
                           background: linear-gradient(270deg,
                              transparentize(adjust-color($secondary-color, $red: -40, $blue: -40, $green: -40), .9) 0%, 
                              transparentize(adjust-color($secondary-color, $red: -40, $blue: -40, $green: -40), 0.6) 100%);         
                           z-index: 1;
                        }

                     }
   
                  }
   
               }

               .leaflet-popup-tip-container {
                  display: none;
               }

               .leaflet-popup-close-button {

                  position: absolute;
                  top: -18px;
                  font-weight: 900;
                  font-size: 1.3rem;
                  color: rgb(255, 255, 255);

               }

            }

         }

      }

   }

   &.no-drag {
      
      #maptile {

         pointer-events: none;

         .leaflet-control-zoom {
            display: none;
         }

         .svg-icon {
            pointer-events: none;
         }

      }

   }

   .select {
      margin-top: 4px;
   }

}