@import '../variables.scss';

#create-activity {

   background: rgb(240, 240, 244);
   padding-bottom: 100px;
   min-height: 100vh;

   h2 {

      font-family: $p-font;

      & + p {
         margin: 5px 0 10px;
      }

   }

   .container {
      max-width: 700px;
   }

   .warning {
      
      position: relative;
      display: inline-block;
      padding: 14px 14px 14px 40px;
      margin-bottom: 4px;
      font-size: .8rem;
      background: rgb(255, 255, 255);
      border: $light-border;
      border-radius: 15px;

      svg {
         position: absolute;
         top: 50%;
         left: 13px;
         width: 16px;
         height: 16px;
         fill: rgb(180, 0, 0);
         @include transform(translateY(-50%));
      }

      p {
         color: rgb(180, 0, 0);
      }

   }

   form {

      .input-container {
         margin-bottom: 5px;
      }

   }


}