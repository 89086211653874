@import '../variables.scss';

#overview { 

   padding-bottom: 100px;
   margin-bottom: 0;
   background: $secondary-color-95-transparant;

   #hero {

      position: relative;
      margin-bottom: 20px;

      #hero-tagline {

         display: flex;
         height: 100%;
         align-items: center;
         position: relative;

         #hero-tagline-text {
            
            padding: 25px 30px;
            margin-right: 0px;
            width: 100%;
            box-sizing: border-box;
            z-index: 1;
            background: $secondary-color-80-transparant;
            border-radius: 25px;
            
            h1 {
               margin-bottom: 3px;
            }
   
            h1, h1 .stress {
               font-family: $p-font;
               font-size: 1.7rem;
               line-height: 2.4rem;
               color: $secondary-color;
            }
      
            h1, p {
               position: relative;
               z-index: 1;
               display: block;
            }

            p {
               font-size: 1.2rem;
               color: $secondary-color;
            }
   
         }
   
      } 

   }

   #filters-results {

      position: relative;
      z-index: 1;

      .container {

         display: flex;
         justify-content: space-between;
         align-items: flex-start;

         #filters {
   
            flex: 0 0 26%;
            padding: 25px;
            border-radius: 25px;
            border: $light-border;
            box-sizing: border-box;
            background: rgb(255, 255, 255);

            @media screen and (max-width: 768px) {
               padding: 15px;
            }

            .form-container {
             
               padding: 0;
               background: transparent;
               border: none;

               form {

                  & > h4 {

                     font-size: 1rem;
                     margin-top: 15px;
   
                     &:first-child {
                        margin-top: 0;
                     }
   
                  }

                  & > * {
                     margin-bottom: 5px;
                  }

                  .two-inputs {
                        
                     display: flex;
                     justify-content: space-between;
   
                     & > div {
                        flex: 0 0 calc(50% - 2px);
                     }
   
                  }
   
                  .form-buttons .btn-container {
                     
                     width: 100%;
                     max-width: 100%;
                     display: none;

                     @media screen and (max-width: 768px) {
                        
                        display: block;
                        flex: 0 0 100%;

                        .btn {
                           padding: 18px 25px;
                        }

                        svg {
                           right: 20px;
                        }

                     }

                  }

               }

            }

            @media screen and (max-width: 1200px) {
               flex: 0 0 28%;
            }
            @media screen and (max-width: 972px) {
               flex: 0 0 38%;
            }
            @media screen and (max-width: 768px) {
               
               position: fixed;
               display: block;
               top: 0;
               bottom: 0;
               width: 100vw;
               max-width: 500px;
               box-sizing: border-box;
               right: -110%;
               z-index: 999999;
               border-right: none;
               border-radius: 0;
               overflow-y: auto;
               @include transition(right 0.3s);

               &.show {
                  right: 0;
               }

               .form-container {
                  position: relative;
                  box-sizing: border-box;
                  padding-top: 50px;
                  background: rgb(255, 255, 255);
                  border: none;
               }

               #close-filters {

                  position: absolute;
                  top: 0;
                  right: 100%;
                  width: max(50px, calc(100vw - 500px));
                  bottom: 50px;

               }

            }

            .checkbox-row {
               
               display: flex;
               justify-content: space-between;

               .checkbox {
                  
                  display: inline-block;
                  flex: 0 0 calc(100% / 7 - 2px);
                  
                  label {
                     box-sizing: border-box;
                     text-align: center;
                     padding: 8px 0;
                     border-radius: 12px;
                     width: 100%;
                  }

               }

            }

            #reset-filters-wrapper {

               margin-top: 10px;
               padding-top: 12px;
               border-top: $light-border-thin;

               #reset-filters {

                  display: block;
                  width: 100%;
                  padding: 8px 0;
                  color: rgb(50, 50, 50);
                  border-radius: 10px;;
                  background: rgb(240, 240, 240);
                  font-size: .75rem;
                  font-weight: 600;
                  text-align: center;
                  cursor: pointer;

               }

            }

         }
   
         #results {
            
            min-height: 75vh;
            flex: 0 0 calc(74% - 20px);

            @media screen and (max-width: 1200px) {
               & {
                  flex: 0 0 calc(72% - 20px);
               }
            }
            @media screen and (max-width: 972px) {
               & {
                  flex: 0 0 calc(62% - 20px);
               }
            }
            @media screen and (max-width: 768px) {
               & {
                  flex: 0 0 100%;
               }
            }

            #results-info {

               display: flex;
               justify-content: space-between;
               font-size: .95rem;
               margin-bottom: 18px;
               padding-bottom: 13px;
               border-bottom: 1px solid $secondary-color-80-transparant;

               #results-info-filter {

                  .filter {
                     
                     padding: 2px 7px;
                     margin: 0 2px;
                     font-size: .9rem;
                     background: rgb(255, 255, 255);
                     border: 1px solid $secondary-color-80-transparant;
                     border-radius: 6px;

                     &:first-child {
                        margin-left: 4px;
                     }

                     svg {
                        
                        height: .6rem;
                        width: .6rem;
                        margin-left: 6px;
                        fill: rgb(200, 200, 200);
                        cursor: pointer;
                        @include transition(fill 0.2s);

                        &:hover {
                           fill: rgb(180, 180, 180);
                        }

                     }

                  }

               }

               #results-info-n {

                  span {
                     font-weight: 600;
                     color: $secondary-color;
                  }

               }

            }

            .ads {
               
               display: flex;
               flex-wrap: wrap;
               margin-right: -15px;

               .ad, .ad-placeholder {

                  flex: 0 0 calc(50% - 15px);
                  align-self: flex-start;
                  margin-right: 15px;
                  margin-bottom: 30px;

                  &:nth-child(2n) {
                     margin-right: 15px;
                  }

                  @media screen and (max-width: 972px) {
                     & {
                        flex: 0 0 calc(100% - 15px);
                     }
                  }
                  @media screen and (max-width: 768px) {
                     & {
                        flex: 0 0 calc(50% - 15px);
                        margin-right: 10px;
                     }
                  }
                  @media screen and (max-width: 692px) {
                     & {
                        flex: 0 0 calc(100% - 15px);
                        margin-bottom: 50px;
                     }
                  }
                  
               }

               #no-ads {
                  
                  position: relative;
                  width: calc(100% - 15px);
                  padding: 18px 20px 18px 48px;
                  box-sizing: border-box;
                  font-size: .9rem;
                  border-radius: 20px;
                  color: $secondary-color;
                  background: $secondary-color-80-transparant;

                  svg {
                     position: absolute;
                     top: 50%;
                     left: 16px;
                     height: 16px;
                     fill: $secondary-color;
                     @include transform(translateY(-50%));
                  }

                  a {
                     color: $secondary-color;
                  }

               }

               .ad-placeholder {

                  border-radius: 10px;
                  box-sizing: border-box;
                  box-shadow: 0 2px 20px rgba(0, 26, 66, .1);
                  border:$light-border;
                  overflow: hidden;

                  .img-placeholder {
                     
                     position: relative;
                     width: 100%;
                     height: 230px;
                     background: $secondary-color-90-transparant;

                     .h3-placeholder {
                        position: absolute;
                        height: 2.6rem;
                        width: 150px;
                        left: 15px;
                        bottom: 15px;
                        border-radius: 6px;
                        background: rgba(255, 255, 255, 0.4);
                     }

                  }

                  .text-placeholder {

                     width: 100%;
                     padding: 14px 10px;
                     box-sizing: border-box;
                     background: rgb(255, 255, 255);
                     border-bottom-left-radius: 10px;
                     border-bottom-right-radius: 10px;

                     .tag-placeholder {

                        display: block;
                        height: calc(8px + .8rem);
                        width: 90px;
                        margin-left: 2px;
                        border-radius: 6px;
                        background: $secondary-color-90-transparant;

                        &:after {
                           animation-delay: 0.3s;
                        }

                     }

                     &:after {
                        animation-delay: 1s;
                     }

                  }

               }

               .placeholder {
            
                  position: relative;
                  display: block !important;
                  overflow: hidden;
      
                  &:after {
                     content: '';
                     position: absolute;
                     top: -20%;
                     left: 40%;
                     width: 280%;
                     height: 140%;
                     background: linear-gradient(105deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0) 60%);
                     animation: shine 1.3s infinite;
                  }

                  &.white:after {
                     background: linear-gradient(105deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.45), rgba(255, 255, 255, 0) 60%);
                  }
      
               }
      
               @keyframes shine {
                  0% {
                     left: 40%;
                  }
                  100% {
                     left: -170%;
                  }
               }

            }

            #show-more {

               display: block;
               width: 100%;
               padding: 12px 18px;
               text-align: center;
               border-radius: 5px;
               font-size: .9rem;
               background: $secondary-color-90-transparant;
               border: 1px dashed transparentize($color: $secondary-color, $amount: 0.70);
               cursor: pointer;
               @include transition(background-color 0.2s);

               &:hover {
                  background: transparentize($color: $secondary-color, $amount: 0.86);
               }

               p {
                  color: $secondary-color;
               }

            }
   
         }

      }

   }

   #show-filters {
      display: none;
   }

   @media screen and (max-width: 768px) {

      #show-filters {
               
         position: fixed;
         display: block;
         right: 10px;
         bottom: 10px;
         width: 64px;
         height: 64px;
         border-radius: 50%;
         background: $cta-color;
         border: 1px solid rgb(255, 255, 255);
         z-index: 2;
         cursor: pointer;
         @include transition(background-color 0.2s);

         &:hover {
            background: adjust-color($color: $cta-color, $red: -15, $green: -15, $blue: -15);
         }

         &:active {
            @include transform(translateY(4px));
            box-shadow: none;
         }

         svg {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 20px;
            width: 20px;
            fill: rgb(255, 255, 255);
            @include transform(translate(-50%, -50%));
         }

      }

   }

}

