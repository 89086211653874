@import '../variables.scss';

.table-wrapper {

   width: 100%;
   padding: 12px 20px;
   background: rgb(255, 255, 255);
   border-radius: 20px;
   border: $light-border;
   border-width: 1px;;
   box-sizing: border-box;

   .input-container {
      
      margin: 5px 0 10px;

      input {
         border-radius: 16px;
      }

   }

   .responsive-table {

      width: 100%;
      box-sizing: border-box;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      table {
         
         width: 100%;
         border-collapse: collapse;
         overflow-y: visible;

         tbody {

            .missing {
               box-sizing: border-box;
               padding: 10px 20px;
               text-align: center;
               color: rgb(180, 180, 180);
            }

         }

      }

      tr {

         &:last-child {

            td {
               border-width: 2px;
            }

         }

      }
      
      th, td {
         padding: 10px 8px;
         text-align: left;
         border-bottom: $light-border;
      }

      th {
         
         text-transform: uppercase;
         font-weight: 900;
         font-size: .7rem;
         color: $secondary-color;

         &.sortable {
            cursor: pointer;
         }

         span {

            display: flex;
            align-items: center;

            svg {
               height: .7rem;
               width: .7rem;
               margin-left: 5px;
               fill: $secondary-color-50-transparant;
            }

         }

      }

      td {
         
         border-width: 1px;
         font-size: .9rem;

         &.empty {
            color: $secondary-color-50-transparant;
         }

         &.tag {

            span {
               
               padding: 4px 8px;
               font-size: .8rem;
               box-sizing: border-box;
               border-radius: 6px;
               background: $secondary-color-90-transparant;
               color: $secondary-color;

               svg {

                  height: .8rem;
                  width: .8rem;
                  margin-right: 8px;
                  fill: $secondary-color;
                  @include transform(translateY(2px));

               }

            }

         }

      }
   
      th:first-child, td:first-child {
         padding-left: 0;
      }

      th:last-child, td:last-child {
         padding-right: 0;
      }

      .align-right {
         
         text-align: right;

         span {
            flex-direction: row-reverse;
         }

      }
      
      @media (min-width: 768px) {
         th, td {
            min-width: 120px; /* Ensure minimum width */
         }
   
         th:nth-child(1), th:nth-child(2),
         td:nth-child(1), td:nth-child(2) {
            width: 30%;
         }
   
         th:nth-child(3), th:nth-child(4), th:nth-child(5),
         td:nth-child(3), td:nth-child(4), td:nth-child(5) {
            width: calc(40% / 3);
         }
      }
      
      @media (max-width: 767px) {
         th, td {
            width: auto;
            white-space: nowrap;
            padding-right: 20px;
         }
      }

   }

}

.table-paginate {

   display: flex;
   justify-content: center;
   margin: 15px 0 5px;

   .table-paginate-buttons {
      
      display: flex;
      justify-content: space-between;

      .table-paginate-btn {
         
         display: flex;
         align-items: center;
         justify-content: center;
         height: 25px;
         width: 25px;
         border-radius: 50%;
         border: $light-border;
         margin: 0 3px;
         cursor: pointer;
         @include transition(background-color 0.2s);

         &:hover:not(.active):not(.disabled) {
            background: $secondary-color-95-transparant;
         }

         span {
            display: block;
            width: 100%;
            font-size: .8rem;
            font-weight: 900;
            color: $secondary-color-50-transparant;
            text-align: center;
         }

         svg {
            height: .7rem;
            width: .7rem;
            fill: $secondary-color-50-transparant;
         }

         &.active {
            
            cursor: default;
            border-color: $secondary-color-50-transparant;

            span {
               color: $secondary-color;
            }

         }

         &.disabled {
            cursor: default;
            opacity: .75;
         }

      }

   }

}

.edit-buttons {

   display: flex;
   align-items: center;
   flex-direction: row-reverse;

   & > span {

      display: block;
      height: 22px;
      cursor: pointer;

      & > svg {
         padding: 3px;
         height: 22px;
         width: 22px;
         margin-left: 4px;
         box-sizing: border-box;
         fill: $secondary-color;
         border-radius: 50%;
         border: $light-border;
      }

   }

}