@import '../variables.scss';

#home {

   #hero {

      position: relative;
      padding: 200px 0;
      margin: 0 20px 0;
      border-radius: 30px;

      @media screen and (max-width: 768px) {
         padding: 130px 0 200px;
      }

      &:after {
         content: '';
         position: absolute;
         top: 15%;
         bottom: 15%;
         left: max(10%, calc(50% - 550px));
         right: max(10%, calc(50% - 550px));
         background: $secondary-color-90-transparant;
         border-radius: 50px;

         @media screen and (max-width: 768px) {
            right: -50px;
         }

      }

      & > svg {
         position: absolute;
         bottom: -30px;
         left: 0;
         width: 100vw;
         z-index: 1;
         @include transform(rotate(-2deg));

         &:first-child {
            width: 120vw;
            left: -10vw;
            bottom: -80px;
            @include transform(rotate(5deg));
         }

         path {
            fill: rgb(255, 255, 255);
            opacity: .06;
         }

      }

      #hero-tagline {

         position: relative;
         display: flex;
         height: 100%;
         box-sizing: border-box;
         align-items: center;
         position: relative;

         @media screen and (max-width: 768px) {
            padding-left: 5px;
            padding-right: 5px;
         }
   
         #hero-tagline-text {
            
            position: relative;
            flex: 0 0 50%;
            z-index: 2;

            @media screen and (max-width: 768px) {
               flex-basis: 60%;
            }

            @media screen and (max-width: 500px) {
               flex-basis: 75%;
            }
   
            h1 {
               
               font-size: 2.5rem;
               line-height: 3.1rem;
               word-break: keep-all;

               @media screen and (max-width: 768px) {
                  font-size: 2rem;
               }

            }

            h2 {
               font-size: 1.4rem;
               line-height: 2rem;
               font-family: $p-font;
               font-weight: 400;
               color: black
            }
      
            h1, h2 {

               display: block;
               padding-right: 50px;
               max-width: 100%;
               box-sizing: border-box;

               @media screen and (max-width: 768px) {
                  width: 100%;
               }

            }
   
         }

         #hero-image {

            position: absolute;
            left: 50%;
            right: 0;
            top: -150px;
            bottom: -150px;
            z-index: 1;

            @media screen and (max-width: 768px) {
               left: calc(100vw - 25% - 100px);
               right: -30px;
               top: -100px;
               bottom: -100px;
            }

            @media screen and (max-width: 500px) {
               left: 70%;
            }

            img {
               
               display: block;
               height: 100%;
               width: 100%;
               object-fit: cover;
               object-position: 80% 50%;
               border-radius: 40px;
               box-shadow: $bottom-shadow-strong;

               @media screen and (max-width: 768px) {
                  object-position: 90% 50%;
               }

            }

            &:after {
               content: '';
               position: absolute;
               top: 0;
               left: 0;
               right: 0;
               bottom: 0;
               border-radius: 40px;
               background: transparentize($secondary-color, .85);
            }

         }
   
      } 

   }

   #hero-search-waves {

      position: relative;
      height: 0;
      width: 100%;

      svg {
      
         position: absolute;
         top: -100px;
         left: -15vw;
         @include transform(rotate(184deg));
         z-index: -1;

         path {
            height: 600px;
            width: 130vw;
            fill: rgba(0, 26, 66, .04);
         }

         &:first-child {
            @include transform(rotate(178deg));

         }

      }

   } 

   #hero-search {
         
      display: block;
      position: relative;
      max-width: 700px;
      width: 95vw;
      margin: -100px auto 100px;
      z-index: 2;

      @media screen and (max-width: 768px) {
         margin-top: -150px;
      }

      .form-container {

         padding: 0;
         border: none;
         background: transparent;

         form {
      
            display: flex;
            box-shadow: $bottom-shadow;

            & > * {
               margin-bottom: 0;
            }

            #hero-search-inputs {

               display: flex;
               flex-wrap: wrap;
               flex: 0 0 calc(100% - 55px);
               z-index: 2;

               .select {
               
                  flex: 0 0 50%;
                  margin-bottom: 0;
   
                  input[type=text] {
                     width: 100%;
                  }
   

                  @media screen and (min-width: 500px) {
                     .selected-values {
                        padding-top: 8px;
                     }
                  }
   
                  & > input[type=text] {

                     border-top-right-radius: 0;
                     border-bottom-right-radius: 0;

                     @media screen and (min-width: 500px) {
                        padding-top: 25px;
                        padding-bottom: 25px;
      
                        &:focus, &.not-empty {
                           padding-top: 31px;
                           padding-bottom: 19px;
                        }
                     }
   
                     @media screen and (max-width: 500px) {
                        
                        padding-left: calc(2px + 0.7rem);
   
                     }
   
                  }

                  &:nth-of-type(2) > input[type=text] {
                     border-left-width: 0;
                     border-top-left-radius: 0;
                     border-bottom-left-radius: 0;
                  }
   
                  @media screen and (max-width: 500px) {
   
                     flex: 0 0 100%;
   
                     .placeholder {
                        left: calc(2px + 0.7rem);
                     }

                     & > input[type=text] {
                        border-right: none;
                     }

                     &:first-child > input[type=text] {
                        border-bottom-left-radius: 0;
                        border-bottom-width: 1px;
                     }

                     &:nth-of-type(2) > input[type=text] {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 20px;
                        border-left-width: 1px;
                        border-top-width: 0;
                     }
   
                     .icon {
                        display: none;
                     }
                     
                     .selected-values {
                        top: calc(1rem + 10px);
                        left: 12px;
                     }
   
                     .options {

                        left: 5px;
                        width: calc(100vw - 35px);

                        .icon {
                           display: block;
                        }

                     }
   
                     svg {
                        right: 12px;
                     }
   
                  }
      
               }               

            }

            .oneline-submit-container {
                  
               border-top-left-radius: 0;
               border-bottom-left-radius: 0;

               @media screen and (max-width: 500px) {
                  width: 55px;
               }

            }
   
         }   

      }  

   
   }

   #popular {

      position: relative;
      margin-bottom: 100px;

      .container {
         position: relative;
         padding: 0 15px;
      }

      h2 {
         
         font-size: 1.8rem;
         margin-bottom: 15px;

         & + .subtitle {
            margin-bottom: 20px;
         }

      }

      .ads {

         position: relative;
         display: flex;
         align-items: flex-start;
         justify-content: space-between;
         margin-bottom: 15px;
         
         @media screen and (max-width: 972px) {

            overflow-x: auto;
            overflow-y: hidden;
            -ms-overflow-style: none;
            scrollbar-width: none;
            margin: 0 -20px 15px;

            &::-webkit-scrollbar {
               display: none;
            }

         }

         .ad, .ad-placeholder {
            flex: 0 0 calc(100% / 3 - 5px);
            margin-bottom: 10px;

            @media screen and (max-width: 972px) {

               &:first-child {
                  margin-left: 40px;
               }

               &:last-child {
                  margin-right: 40px;
               }

               flex: 0 0 min(350px, 90vw);
               margin-right: 10px;
               max-width: 500px;
   
            }

            @media screen and (max-width: 768px) {

               &:first-child {
                  margin-left: 20px;
               }

               &:last-child {
                  margin-right: 20px;
               }

            }


         }

         .ad-placeholder {

            border-radius: 20px;
            box-sizing: border-box;
            box-shadow: 0 2px 20px rgba(0, 26, 66, .1);
            border:$light-border;
            overflow: hidden;
            z-index: 10;

            .img-placeholder {
               
               position: relative;
               width: 100%;
               height: 230px;
               background: rgb(220, 220, 220);

               .h3-placeholder {
                  position: absolute;
                  height: 2.6rem;
                  width: 150px;
                  left: 15px;
                  bottom: 15px;
                  border-radius: 6px;
                  background: rgba(255, 255, 255, 0.4);
               }

            }

            .text-placeholder {

               width: 100%;
               padding: 14px 10px;
               box-sizing: border-box;
               background: rgb(255, 255, 255);
               border-bottom-left-radius: 20px;
               border-bottom-right-radius: 20px;

               .tag-placeholder {

                  display: block;
                  height: calc(8px + .8rem);
                  width: 90px;
                  margin-left: 2px;
                  border-radius: 6px;
                  background: $secondary-color-90-transparant;

                  &:after {
                     animation-delay: 0.3s;
                  }

               }

               &:after {
                  animation-delay: 1s;
               }

            }

         }

         .placeholder {
            
            position: relative;
            display: block !important;
            overflow: hidden;

            &:after {
               content: '';
               position: absolute;
               top: -20%;
               left: 40%;
               width: 280%;
               height: 140%;
               background: linear-gradient(105deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0) 60%);
               animation: shine 1.3s infinite;
            }

            &.white:after {
               background: linear-gradient(105deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.45), rgba(255, 255, 255, 0) 60%);
            }

         }

         @keyframes shine {
            0% {
               left: 40%;
            }
            100% {
               left: -170%;
            }
         }

      }

      .btn-container {
         display: block;
         max-width: 300px;
         width: 90%;
         margin: 0 auto;
      }

      .light-blue {
         position: absolute;
         top: 30%;
         left: 50%;
         width: 100%;
         max-width: 95vw;
         height: 180px;
         border-radius: 30px;
         @include transform(translateX(-50%));
      }

      .light-blue {
         background: $secondary-color-90-transparant;
      }

   }

   #places, #activities {

      margin-bottom: 170px;
      padding: 25px 0;

      .container {

         position: relative;

         &:after {
            content: '';
            position: absolute;
            top: 90px;
            bottom: -20px;
            left: 30px;
            right: 30px;
            border-radius: 7px;
            z-index: -1
         }

         h2 {
         
            font-size: 1.8rem;
            margin-bottom: 5px;

            & + .subtitle {

               margin-bottom: 10px;

            }

         }

         .tiles {

            display: flex;
            overflow-x: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
               display: none;
            }

            .tile {
            
               position: relative;
               flex: 0 0 calc(20% - 10px);
               margin-right: 10px;
               border-radius: 20px;
               border:$light-border;
               box-shadow: 0 2px 20px rgba(0, 26, 66, .1);

               @media screen and (max-width: 972px) {
                  flex: 0 0 230px;
               }
   
               img {
                  display: block;
                  width: 100%;
                  height: 150px;
                  border-radius: 20px;
               }

               h3 {
                  position: absolute;
                  left: 10px;
                  bottom: 10px;
                  color: rgb(255, 255, 255);
                  z-index: 1;
               }

               &:after {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  border-radius: 20px;
                  background: linear-gradient(
                     transparentize(adjust-color($secondary-color, $red: -20, $blue: -20, $green: -20), 0.88) 20%, 
                     transparentize(adjust-color($secondary-color, $red: -20, $blue: -20, $green: -20), 0.5) 100%);         
               }
   
            }

            & + a {
               
               margin-top: 7px;
               color: $secondary-color;
               float: right;

               svg {
                  height: .7em;
                  width: .7em;
                  margin-left: 5px;
                  fill: $secondary-color;
               }

            }
   
         }
         
      }

   }

   #places {
      padding-top: 50px;
      margin-bottom: 0px;
      background: linear-gradient(0deg, transparentize($color: $secondary-color, $amount: 0.9),
                                         transparentize($color: $secondary-color, $amount: 0.95));
   }
   #activities {
      padding-bottom: 75px;
      background: linear-gradient(0deg, transparentize($color: $secondary-color, $amount: 0.85),
                                         transparentize($color: $secondary-color, $amount: 0.9));
   }

   #categories {

      padding: 100px 0 150px;

      .container {
         display: flex;
         flex-wrap: wrap;
         justify-content: space-between;
         align-items: center;
      }

      .left {

         flex: 0 0 calc(43% - 10px);

         @media screen and (max-width: 768px) {
            
            & {
               flex: 0 0 100%;
            }

         }

         h2 {
            margin-bottom: 15px;
         }

         p {
            margin-bottom: 20px;
            line-height: 1.8rem;
            font-size: 1.2rem;
         }

      }

      .right {

         position: relative;
         display: flex;
         flex-wrap: wrap;
         justify-content: space-between;
         flex: 0 0 calc(55% - 30px);

         @media screen and (max-width: 768px) {
            
            & {
               flex: 0 0 100%;
               margin-top: 100px;
            }

         }

         &:after, &:before {
            content: '';
            position: absolute;
            border-radius: 20px;
         }

         &:before {
            top: -40px;
            left: 50px;
            right: 50px;
            bottom: 50px;
            background: $primary-color;
         }
         
         &:after {
            top: -30px;
            left: 60px;
            right: 60px;
            bottom: 60px;
            border-radius: 20px;
            background: $secondary-color;
         }

         .category {

            position: relative;
            flex: 0 0 calc(50% - 10px);
            margin: 5px;
            height: 180px;
            border-radius: 20px;
            box-shadow: 0 2px 20px rgba(0, 26, 66, .1);
            z-index: 2;

            h4 {
               position: absolute;
               top: 50%;
               left: 50%;
               font-size: 1.2rem;
               transform: translate(-50%, -50%);
               color: rgb(255, 255, 255);
               z-index: 2;
               text-align: center;
            }

            img {
               width: 100%;
               height: 100%;
               object-fit: cover;
               border-radius: 20px;
            }

            &:after {
               content: '';
               position: absolute;
               top: 0;
               left: 0;
               right: 0;
               bottom: -1px;
               border-radius: 20px;
               background: linear-gradient(
                  transparentize(adjust-color($secondary-color, $red: -20, $blue: -20, $green: -20), 0.88) 0%, 
                  transparentize(adjust-color($secondary-color, $red: -20, $blue: -20, $green: -20), 0.5) 100%);
               z-index: 0;
               @include transition(opacity 0.2s);
            }

            &:hover {

               &:after {
                  opacity: .7;
               }
         
            }

         }

      }

   }

   #partner-cta {

      margin-bottom: 100px;

      .content-wrapper {

         display: flex;
         padding: 30px;
         border-radius: 30px;
         background: $secondary-color-95-transparant;

         .image {

            align-self: stretch;
            flex: 0 0 45%;
            padding-right: 30px;
            box-sizing: border-box;

            @media screen and (max-width: 572px) {
               flex: 0 0 30%;
            }
            
            img {
               
               width: 100%;
               height: 200px;
               border-radius: 20px;
               object-fit: cover;
               object-position: 50% 30%;

               @media screen and (max-width: 768px) {
                  height: 100%;
               }

               @media screen and (max-width: 572px) {
                  margin-left: -150px;
                  width: calc(100% + 150px);
               }

            }

         }

         .text {

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            flex: 1 1 50%;
            
            & > div:not(.btn-container) {
               width: 100%;
            }

            span {
               display: block;
               margin-bottom: 10px;
               text-transform: uppercase;
               font-weight: 900;
               font-size: .8rem;
               color: $secondary-color-50-transparant;
            }

            h2 {
               font-size: 1.8rem;
               line-height: 2rem;
               margin-bottom: 10px;
            }

            p {
               margin-bottom: 15px;
               font-size: 1.2rem;
            }

            @media screen and (max-width: 572px) {
               .btn-container {
                  min-width: auto;
                  width: 100%;
               }
            }

         }

      }

   }

}
