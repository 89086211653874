@import '../variables.scss';

#rating {

    background: $secondary-color-95-transparant;
    padding: 100px 0 300px;
    min-height: calc(100 * var(--vh, 1vh));

    .form-container {
        
        display: block;
        margin: 0 auto;
        max-width: 550px;

        form {

            .rating {
                margin-bottom: 15px;
            }

            .checkbox {
                margin-top: -4px;
            }

        }

    }

}