@import '../../variables.scss';

.consent-input-container {

   label {

      display: flex;
      padding: 5px 0;
      align-items: center;
      cursor: pointer;

      div {
         
         position: relative;
         flex: 0 0 12px;
         height: 12px;
         margin-right: 10px;
         border-radius: 16px;
         border: 2px solid $secondary-color-80-transparant;

         svg {
            position: absolute;
            height: 20px;
            width: 20px;
            top: -4px;
            left: -4px;
            fill: $secondary-color;
            opacity: 0;
            @include transform(scale(0));
            @include transition(opacity .2s, transform .2s);
         }

      }

      p {
         font-size: .8rem;
      }

   }

   input[type=checkbox] {
      
      position: absolute;
      opacity: 0;
      width: .1px;
      height: .1px;
      visibility: hidden;

      &:checked ~ label div svg {
         @include transform(scale(1));
         opacity: 1;
      }

   }

}