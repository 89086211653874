@import '../variables.scss';

#hub {

      min-height: 100vh;
      padding-bottom: 70px;
      background: $secondary-color-95-transparant; 

      #hero {
            
            margin-bottom: 0;
            box-sizing: border-box;

            & ~ section {
                  
                  display: none;

                  &.active {
                        display: block;
                  }

            }

            #hero-title {

                  padding: 85px 0 0;

                  h2 {
                        font-family: $p-font;
                        font-size: 1.7rem;
                  }

            }     

            #switcher {
                  
                  position: relative;
                  margin: 15px 0 10px -2px;
                  font-family: $p-font;
                  font-size: .7rem;
                  text-transform: uppercase;
                  font-weight: 790;
                  color: $secondary-color-80-transparant;

                  @media screen and (max-width: 500px) {

                        &:after {
                              content: '';
                              position: absolute;
                              top: 0;
                              right: 0;
                              bottom: 5px;
                              width: 30px;
                              background: linear-gradient(90deg,
                                    transparentize($secondary-color-95-transparant, 1),
                                    transparentize($secondary-color-95-transparant, 0) 50%,
                              );
                        }

                  }

                  .container {
                        
                        display: block;
                        white-space: nowrap;
                        overflow-x: auto;
                        -ms-overflow-style: none;
                        scrollbar-width: none;

                        @media screen and (max-width: 500px) {
                              box-sizing: border-box;
                              padding-right: 15px;
                        }

                        .option {
                              
                              position: relative;
                              display: inline-block;
                              padding: 16px 16px;
                              width: 110px;
                              margin: 0 2px;
                              text-align: center;
                              box-sizing: border-box;
                              cursor: pointer;
                              border: $light-border;
                              border-radius: 10px;
                              background: rgb(255, 255, 255);
                              color: $secondary-color-50-transparant;
                              text-align: center;
                              overflow: hidden;
                              @include transition(color 0.2s, border 0.2s);

                              svg {
                                    display: block;
                                    margin: 0 auto;
                                    height: 1em;
                                    width: 1em;
                                    margin-bottom: 5px;
                                    fill: $secondary-color-50-transparant;
                                    @include transition(fill 0.2s);
                              }

                              &.wellbits {
                                    
                                    color: $wellbits-color-50-transparant;
                                    border-color: $wellbits-color-80-transparant;
                                    background: $wellbits-color-95-transparant;

                                    svg {
                                          fill: $wellbits-color-50-transparant;
                                    }

                              }

                        }

                        .active {
                        
                              border-color: $secondary-color-50-transparant;
                              color: $secondary-color;
                              cursor: default;
      
                              svg {
                                    fill: $secondary-color;
                              }

                              &.wellbits {
                               
                                    border-color: $wellbits-color-50-transparant;
                                    color: $wellbits-color;

                                    svg {
                                          fill: $wellbits-color;
                                    }

                              }
      
                        }

                  }

            }

      }


      #bookings-info {

            & > div {

                  h3 {
                        
                        &:first-of-type {
                              margin-top: 0;
                        }

                  }

            }

            #bookings, #history {

                  display: flex;
                  flex-wrap: wrap;
                  margin-right: -5px;

                  .booking {
                        
                        flex: 0 0 calc(25% - 5px);
                        margin-right: 5px;
                        margin-bottom: 15px;

                        .ad {
                              
                              flex: 0 0 calc(50% - 5px);
                              z-index: 1;

                              .options {
                                    
                                    border-bottom-left-radius: 0;
                                    border-bottom-right-radius: 0;

                              }
                              
                              .img-container, .img-container::after, .img-container img {
                                    
                                    height: 170px;
                              }

                              @media screen and (max-width: 768px) {
                                    flex: 0 0 100%;
                              }

                        }

                        .buttons {

                              display: flex;
                              flex-wrap: wrap;
                              align-self: start;
                              justify-content: space-between;
                              flex: 0 0 calc(50% - 5px);

                              @media screen and (max-width: 768px) {
                                    flex: 0 0 100%;
                              }

                              .booking-info {

                                    position: relative;
                                    flex: 0 0 100%;
                                    padding: 10px 15px;
                                    box-sizing: border-box;
                                    margin-top: -1px;
                                    background: rgb(255, 255, 255);
                                    border-radius: 0 0 10px 10px;
                                    border: $light-border;
                                    border-top: none;
                                    font-family: $p-font;
                                    font-size: .75rem;

                                    ul {

                                          li {
                                                
                                                position: relative;
                                                padding: 4px 0 4px 28px;
                                                box-sizing: border-box;

                                                &:nth-last-child(3) {
                                                      text-decoration: underline;
                                                }

                                                svg {
                                                      position: absolute;
                                                      display: inline-block;
                                                      height: 1.1em;
                                                      left: 0;
                                                      top: 50%;
                                                      margin-right: 5px;
                                                      fill: $secondary-color;
                                                      @include transform(translateY(-50%));
                                                }

                                          }

                                    }

                              }

                              .btn-container {
                                    width: 100%;
                              }

                              & p {
                                    padding: 9px 0 2px;
                                    margin-top: 6px;
                                    border-top: 1px solid transparentize($color: $secondary-color, $amount: 0.85);
                              }

                              & form {
                                    padding: 10px 0 2px;
                                    margin-top: 6px;
                                    border-top: 1px solid transparentize($color: $secondary-color, $amount: 0.85);
                              }

                              .cancel {

                                    input[type=submit] {
                                          color: $error-color;
                                          padding: 0;
                                          background-color: transparent;
                                          border: none;
                                          border-radius: 0;
                                    }

                              }

                        }

                  }      

            }
      }

}