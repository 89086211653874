@import '../variables.scss';

#notification-container {

    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 350px;
    max-width: calc(100vw - 30px);
    z-index: 1000;

    .notification {
        
        position: relative;
        width: 100%;
        box-sizing: border-box;
        padding: 15px 20px;
        margin-bottom: 10px;
        background-color: rgb(255, 255, 255);
        border: $light-border;
        border-radius: 15px;
        box-shadow: $bottom-shadow;
        opacity: 1;
        transform-origin: 0 100%;
        overflow: hidden;
        @include transition(opacity 0.3s, transform 0.3s);

        &.fading {
            opacity: 0;
            @include transform(scaleY(0));
        }

        .progress-bar {
            
            position: absolute;
            top: 0;
            left: 0;
            height: 3px;
            width: 100%;
            transform-origin: left center;
            background: $secondary-color-50-transparant;
            animation: progress 5s linear forwards;

        }

        @keyframes progress {
            0% {
                @include transform(scaleX(1));
            }
            100% {
                @include transform(scaleX(0));
            }
        }
     
        h4 {
            font-size: .7rem;
            margin-bottom: 3px;
            text-transform: uppercase;
        }

        p {
            padding-right: 25px;
            font-size: .85rem;
            box-sizing: border-box;
        }

        & > span {
            
            position: absolute;
            right: 10px;
            top: 50%;
            width: 28px;
            height: 28px;
            box-sizing: border-box;
            cursor: pointer;
            @include transform(translateY(-50%));

            svg {
                width: 28px;
                height: 28px;
                padding: 8px;
                box-sizing: border-box;
                fill: $secondary-color-50-transparant;
            }

        }

        &:last-child {
            margin-bottom: 0;
        }

    }

}
  