@mixin transition($x...) {
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}

@mixin transform($x...){
  -webkit-transform: $x;
  -moz-transform: $x;
  -ms-transform: $x;
  -o-transform: $x;
  transform: $x;
}

$primary-color: #EDB307;
$secondary-color: #06532F;
$cta-color: #EB6631;
$p-color: #1D1D1B;
$header-color: #06532F;

$secondary-color-20-transparant: #387559;
$secondary-color-50-transparant: #83a997;
$secondary-color-80-transparant: #cdddd5;
$secondary-color-90-transparant: #e6eeea;
$secondary-color-95-transparant: #f3f6f5; 

$wellbits-color: rgb(165, 85, 140);
$wellbits-color-50-transparant: #d2aac6;
$wellbits-color-80-transparant: #eddde8;
$wellbits-color-90-transparant: #f6eef4;
$wellbits-color-95-transparant: #fbf7f9;


$error-color: rgb(200, 100, 100);

$dark-border: 2px solid $secondary-color-20-transparant;
$dark-border-thin: 1px solid $secondary-color-20-transparant;
$light-border: 1px solid $secondary-color-80-transparant; // 2px solid $secondary-color-80-transparant;
$light-border-thin: 1px solid $secondary-color-80-transparant;

$header-font: 'Kalnia', sans-serif; // 'Raleway', sans-serif;
$header-font-weight: 800;
$button-font : 'Nunito', sans-serif;
$button-font-weight: 900;
$p-font: 'Nunito', sans-serif; // 'Work Sans', sans-serif;

$shadow-color: rgba(0, 0, 0, 0.03);
$shadow-color-strong: rgba(0, 0, 0, 0.09);
$left-shadow: -8px 0 10px $shadow-color;
$bottom-shadow: 0 8px 10px $shadow-color;
$bottom-shadow-strong: 0 8px 14px $shadow-color-strong;