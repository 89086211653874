@import '../variables.scss';

#learn-overview {

   min-height: 100vh;
   margin-top: -20px;
   padding-bottom: 100px;
   background: linear-gradient(0deg,
      transparentize(adjust-color($secondary-color, $red: -20, $blue: -20, $green: -20), 0.98) 20%, 
      transparentize(adjust-color($secondary-color, $red: -20, $blue: -20, $green: -20), 0.92) 100%); 

   .container .container {
      margin: 0;
      max-width: 900px;
   }

   #hero {

      padding: 50px 0 ;
      background: linear-gradient(adjust-color($secondary-color, $red: -20, $blue: -20, $green: -20), adjust-color($secondary-color, $red: 20, $blue: 20, $green: 20));

      h2, p {
         color: rgb(255, 255, 255);
      }

      h2 {
         margin-bottom: 5px;
      }

      p {
         font-size: 1.3rem;
      }

   }

   #learn-container {

      display: flex;
      justify-content: space-between;

      #learn-content {

         flex: 0 0 calc(70% - 50px);
         display: flex;
         flex-wrap: wrap;

         @media screen and (max-width: 1400px) {
            flex: 0 0 100%;
         }
   
         section {
            width: 100%;
         }

         section > h2, section > p {
            flex: 0 0 100%;
         }
   
         section > h2 {
            
            position: relative;
            margin-top: 50px;
            padding-top: 50px;
            font-size: 1.5rem;
            margin-bottom: 8px;
            border-top: 2px solid rgba(0, 0, 0, 0.11);

         }

         section:first-child {

            & > h2 {
               margin-top: 0;
               border-top: none;
            }

         }
   
         section > p {
            margin-bottom: 20px;
            font-size: 1.1rem;
         }

         #learn-activities {

            display: flex;
            flex-wrap: wrap;
            margin-bottom: -30px;

            #no-results-message {
            
               flex: 0 0 100%;
               margin-bottom: 20px;
      
               p {
                  display: inline-block;
                  padding: 10px 15px;
                  font-size: .8rem;
                  color: $secondary-color;
                  border-radius: 7px;
                  background: $secondary-color-95-transparant;
               }
      
            }
      
            .search-result {
      
               flex: 0 0 calc(33.33% - 10px);
               margin-bottom: 30px;
               margin-right: 10px;
               border-radius: 10px;
               box-sizing: border-box;
               background: rgb(255, 255, 255);
               border:$light-border;;
      
               @media screen and (max-width: 968px) {
                  flex: 0 0 calc(50% - 10px);
               }
      
               img {
                  height: 140px;
                  width: 100%;
                  border-radius: 10px 10px 0 0;
                  object-fit: cover;
               }
      
               h4 {
                  padding: 0 20px;
                  padding-top: 10px;
                  margin-bottom: 5px;
               }
      
               p {
                  padding: 0 20px 18px;
                  font-size: .9rem;
                  line-height: 1.1rem;
               }
      
            }
      
         }

         #terms-switcher {

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 100px;

            .switch {

               flex: 0 0 calc(33.33% - 5px);
               padding: 30px 20px;
               margin-top: 10px;
               box-sizing: border-box;
               background: rgb(255, 255, 255);
               border-radius: 10px;
               border: $light-border;
               cursor: pointer;
               @include transition(opacity 0.2s);
               
               h3 {
                  font-size: 1rem;
                  text-align: center;
               }

               svg {
                  display: block;
                  margin: 0 auto;
                  width: 100px;
                  height: 100px;
                  margin-bottom: 20px;
               }

               @media screen and (max-width: 972px) {
                  
                  flex: 0 0 100%;
                  margin-bottom: 10px;

                  svg {
                     height: 60px;
                  }

               }

            }

         }

         #learn-faq {

            #faq-switcher {

               display: flex;
               flex-wrap: wrap;
               justify-content: space-between;
               margin-bottom: 10px;

               .switch {

                  flex: 0 0 calc(33.33% - 5px);
                  padding: 30px 20px;
                  box-sizing: border-box;
                  background: rgb(255, 255, 255);
                  border-radius: 10px;
                  border: $light-border;
                  opacity: .5;
                  cursor: pointer;
                  @include transition(opacity 0.2s);

                  &.active {
                     opacity: 1;
                  }

                  
                  h3 {
                     font-size: 1rem;
                     text-align: center;
                  }

                  svg {
                     display: block;
                     margin: 0 auto;
                     width: 100px;
                     height: 100px;
                     margin-bottom: 20px;
                  }

                  @media screen and (max-width: 972px) {
                     
                     flex: 0 0 100%;
                     margin-bottom: 10px;

                     svg {
                        height: 60px;
                     }

                  }

               }

            }

            #questions-and-answers {

               .question-and-answer {

                  width: 100%;
                  margin-bottom: 10px;
                  box-sizing: border-box;
                  background: rgb(255, 255, 255);
                  border-radius: 10px;
                  border: $light-border;

                  h4 {
                     
                     position: relative;
                     font-size: 1rem;
                     padding: 20px 45px 20px 20px;
                     font-family: $p-font;
                     cursor: pointer;

                     svg {
                        position: absolute;
                        top: 50%;
                        right: 20px;
                        height: .7em;
                        width: .7em;
                        fill: $secondary-color;
                        @include transform(translateY(-50%) rotate(90deg));
                        @include transition(transform 0.2s);
                     }

                  }

                  p {
                     max-height: 0;
                     box-sizing: border-box;
                     padding: 0px 20px;
                     background: $secondary-color-95-transparant;
                     overflow: hidden;
                     @include transition(max-height 0.4s, padding 0.4s);
                  }

                  ul {

                     margin: 10px 0;
      
                     li {
                        
                        position: relative;
                        margin: 8px 0;
                        padding-left: 30px;
                        font-family: $p-font;
                        box-sizing: border-box;
      
                        &:before {
                           content: '•';
                           position: absolute;
                           top: 50%;
                           left: 0;
                           color: $secondary-color;
                           @include transform(translateY(-50%));
                        }
      
                     }
      
                  }

                  &.show {

                     h4 {
                        
                        border-bottom: 1px solid transparentize($color:  $secondary-color, $amount: 0.8);

                        svg {
                           @include transform(translateY(-50%) rotate(270deg));
                        }

                     }

                     p {
                        max-height: 500px;
                        padding: 20px 20px;
                        display: block;
                     }

                  }

               }

            }

         }

         #learn-privacy, #learn-content, #learn-terms-and-conditions {

            h3 {
               margin-top: 10px;
            }

            p, li {
               font-size: 1.1rem;
               line-height: 1.6rem;
            }

            ul {
               
               margin: 5px 0;

               li {
                  
                  position: relative;
                  margin: 10px 0;
                  padding-left: 30px;
                  font-family: $p-font;
                  box-sizing: border-box;

                  &:before {
                     content: '•';
                     position: absolute;
                     top: 50%;
                     left: 0;
                     color: $secondary-color;
                     @include transform(translateY(-50%));
                  }

               }

            }

         }
   
      }

      #learn-nav {
         
         padding-top: 40px;
         flex: 0 0 calc(30% - 10px);

         ul {
            
            padding: 15px 20px;
            border-radius: 10px;
            background: rgb(255, 255, 255);
            border:$light-border;;
            font-family: $p-font;

            li {
               padding: 2px 0;
               color: $secondary-color;
               font-weight: 700;
            }

         }

      }

   }

}