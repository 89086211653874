@import '../variables.scss';

.ad {

   position: relative;
   display: block;
   border-radius: 20px;
   background: $secondary-color-90-transparant;
   box-sizing: border-box;
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   -webkit-user-drag: none;

   * {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-user-drag: none;
   }

   &.static {
      pointer-events: none;
   }

   .tags {

      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      z-index: 1;

      .tag {
         
         display: inline-block;
         margin: 0 4px 4px 0;
         padding: 7px 10px 8px 8px;
         font-size: .75rem;
         font-family: $p-font;
         font-weight: 900;
         border-radius: 7px;
         color: rgb(255, 255, 255);
         border: 2px solid rgb(255, 255, 255);
         background: rgba(255, 255, 255, .25);

         svg {
            height: 1em;
            width: 1em;
            margin-right: 4px;
            fill: rgb(255, 255, 255);
            @include transform(translateY(2px));
         }

      }

   }

   .img-container {

      position: relative;
      width: 100%;
      height: 260px;
      background: rgb(219, 219, 219);
      border-radius: 20px 20px 0 0;
      box-sizing: border-box;
      @include transition(opacity 0.2s);

      img {
         position: absolute;
         top: 0;
         left: 0;
         height: 100%;
         width: 100%;
         border-radius: 20px 20px 0 0 !important;
         object-fit: cover;
         object-position: 45% 40%;
      }

      &:after {
         content: '';
         position: absolute;
         top: 0;
         left: 0;
         right: 0;
         height: 100%;
         border-radius: 20px 20px 0 0;
         opacity: 0;
         z-index: 0;
         @include transition(opacity 0.2s);
      }

      .img-placeholder {
         
         width: 100%;
         height: 100%;
         border-radius: 20px 20px 0 0;
         background: transparentize($color: $secondary-color, $amount: 0.3);

         svg {
            position: absolute;
            height: 48px;
            width: 48px;
            top: 50%;
            left: 50%;
            fill: rgba(255, 255, 255, .35);
            @include transform(translate(-50%, -50%));
         }

      }

   }

   .favorite-btn {
      position: absolute;
      right: 15px;
      top: 15px;
   }

   .rating {
      
      padding: 0;
      box-sizing: border-box;
      
      .rating-half {
         border-color: white;;
      }

      span {
         color: white;
         @include transform(translateY(1px));
      }
      
   }

   .text {
      
      display: flex;
      position: absolute;
      justify-content: space-between;
      align-items: flex-end;
      bottom: 0;
      width: 100%;
      box-sizing: border-box;
      padding: 40px 20px 15px;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .8) 80%);
      z-index: 1;

      h3 {

         color: rgb(255, 255, 255);
         font-weight: 400;

         &, span {
            font-family: $p-font;
         }

         span {
            font-size: 1.1rem;
            font-weight: 900;
         }

      }

      .city {

         font-size: 1rem;
         color: rgb(255, 255, 255);

      }

      .rating {
         padding-top: 2px;
      }

      .right {

         top: 0;
         left: 20px;
         text-align: right;

      }

      .old-price {
         
         position: relative;
         margin-right: 6px;
         opacity: .6;

         &:after {
            content: '';
            position: absolute;
            top: calc(50%);
            left: 0;
            width: 105%;
            height: 2px;
            background: rgb(255, 255, 255);
            @include transform(rotate(-20deg));
         }

      }

   }

   .options {

      align-items: center;
      position: relative;
      min-height: calc(.8rem + 34px);
      margin: 0;
      padding: 22px 20px 22px;
      box-sizing: border-box;
      border:$light-border;
      background: rgb(255, 255, 255);
      border-top: none;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;

      .subtitle {

         position: relative;
         height: 53px;

         .subtitle-options {
            
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding-top: 13px;
            flex-wrap: nowrap;
            overflow-x: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            cursor: grab;
            font-size: .7rem;
            line-height: .8rem;
            color: $secondary-color;
            align-items: center;

            &:active {
               cursor: grabbing;
            }
      
   
             & > span {
   
               position: relative;
               flex: 0 0 auto;
               padding: 13px 8px 11px;
               margin: 0 4px 5px 0;
               border-radius: 6px;
               background: $secondary-color-90-transparant;
   
               .category {
   
                  position: absolute;
                  top: -2px;
                  left: 4px;
                  padding: 3px 4px;
                  font-size: .57rem;
                  line-height: .6rem;
                  border-radius: 3px;
                  text-transform: uppercase;
                  font-weight: 700;
                  background: rgb(255, 255, 255);
                  border: $light-border-thin;
                  @include transform(translateY(-50%));
   
               }
   
            }
   
         }

      }

      .daytime {

         display: flex;
         align-items: center;
         justify-content: space-between;
         flex: 0 0 90px;
         margin-top: 2px;
         padding: 8px 10px;
         box-sizing: border-box;
         font-size: .75rem;
         font-family: $p-font;
         font-weight: 400;
         color: rgb(100, 100, 100);
         background-color: rgb(240, 240, 240);
         border-radius: 10px;

         & > div {

            display: flex;
            align-items: center;
            

            &.hidden {
               width: 0;
               padding-left: 0;
               padding-right: 0;
               overflow: hidden;
            }

            svg {
               height: .8rem;
               width: .9rem;
               margin: 0 8px 0 0;
               fill: $secondary-color;
               @include transform(translateY(-1px));
            }

            &.days {

               span {
                  
                  padding: 0 3px;
                  line-height: 1.5rem;
                  border-radius: 4px;
                  text-align: center;
                  cursor: default;
                  color: rgb(210, 210, 210);
                  @include transition(color 0.2s);

                  &.active {
                     
                     cursor: pointer;
                     color: rgb(80, 80, 80);
                     
                     &:hover {
                        color: rgb(50, 50, 50);
                     }

                  }

                  &.selected, &.selected:hover {
                     color: $secondary-color;
                     font-weight: 600;
                  }

               }

            }

            &.time {
               color: $secondary-color;
               font-weight: 600;
            }

         }

      }


      .people {
         
         padding: 0;
         right: 0;
         font-size: .9rem;

         svg {
            
            position: relative;
            height: 1em;
            width: 1em;
            margin-right: 4px;
            @include transform(translateY(2px));
            z-index: 1;

         }

      }

   }

   a {

      & > div {
         @include transition(border 0.5s);
      }

   }

   a.target {
      
      & > div {
         border-color: $secondary-color;
      }

   }

}
