@import '../../variables.scss';

.favorite-form {
    
    .input-container, .form-buttons {
        position: absolute;
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        visibility: hidden;
    }

    .favorite-btn {
        
        display: inline-block;
        border: 2px solid rgb(255, 255, 255);
        padding: 4px 4px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 12px;
        cursor: pointer;

        &.text {

            padding: 2px 13px 6px;
            border-radius: 10px;
            background: rgb(255, 255, 255);
            border: $light-border;

            &.favorite span {
                color: rgb(250, 50, 50);
            }

            span {
                font-size: .8rem;
                @include transition(color 0.2s);
            }

            svg {
                display: inline-block;
                height: .6rem;
                width: .6rem;
                margin-left: 8px;
                margin-right: -2px;
                fill: $secondary-color;
            }

        }

        svg {

            height: .75rem;
            fill: rgb(255, 255, 255);
            @include transition(fill 0.2s);

        }

        &.favorite, &.favorite:hover {
        
            svg {
                fill: rgb(250, 50, 50);
            }
    
        }

    }

}
