@import '../variables.scss';

#calculator {

   min-height: 100vh;
   padding: 0 0 40px;
   box-sizing: border-box;
   background: linear-gradient(0deg,
      transparentize(adjust-color($secondary-color, $red: -20, $blue: -20, $green: -20), 0.98) 20%, 
      transparentize(adjust-color($secondary-color, $red: -20, $blue: -20, $green: -20), 0.92) 100%); 

}